import { NETWORK_STATUSES } from '@/services/http'
import * as api from '@/services/api'

const state = {
  showInfo: false,
  networkStatus: NETWORK_STATUSES.WAITING
}

const getters = {
  getPepertonInfo: (state) => {
    return () => {
      return state.showInfo
    }
  }
}

const actions = {
  goToPapertonIfUserHasAccess ({ commit }) {
    commit('setPepertonNetworkStatus', NETWORK_STATUSES.GETTING)
    api.getPapertonLoginUrl()
      .then(loginUrl => {
        commit('setPepertonNetworkStatus', NETWORK_STATUSES.SUCCESS)
        if (loginUrl) {
          window.location.href = loginUrl
        } else {
          commit('setShowInfo', true)
        }
      })
      .catch((error) => {
        console.error(error)
        commit('setPepertonNetworkStatus', NETWORK_STATUSES.ERROR)
        commit('setShowInfo', true)
      })
  }
}

const mutations = {
  setShowInfo (state, showInfo) {
    state.showInfo = showInfo
  },
  setPepertonNetworkStatus (state, status) {
    state.networkStatus = status
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
