import { NETWORK_STATUSES } from '@/services/http'
import * as api from '@/services/api'

const state = {
  terms: null,
  networkStatus: NETWORK_STATUSES.WAITING
}

const actions = {
  getTerms ({ commit }) {
    commit('setTermsNetworkStatus', NETWORK_STATUSES.GETTING)
    commit('setTerms', null)
    api.getTerms()
      .then(terms => {
        commit('setTermsNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setTerms', terms)
      })
      .catch((error) => {
        console.error(error)
        commit('setTermsNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  approveTerm ({ dispatch, commit }, term) {
    commit('setTermsNetworkStatus', NETWORK_STATUSES.SUBMITTING)
    const termData = {
      ...term,
      accepted: true
    }
    api.updateTerm(termData)
      .then(termResponse => {
        commit('setTermsNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setTerm', termResponse)
        dispatch('showInfo', 'Användarvillkoret är nu godkänt')
      })
      .catch((error) => {
        console.error(error)
        commit('setTermsNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  revokeTerm ({ dispatch, commit }, term) {
    commit('setTermsNetworkStatus', NETWORK_STATUSES.SUBMITTING)
    const termData = {
      ...term,
      accepted: false
    }
    api.updateTerm(termData)
      .then(termResponse => {
        commit('setTermsNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setTerm', termResponse)
        dispatch('showInfo', 'Godkännande tillbakadraget')
      })
      .catch((error) => {
        console.error(error)
        commit('setTermsNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  }
}

const mutations = {
  setTerms (state, terms) {
    state.terms = terms
  },
  setTerm (state, term) {
    const index = state.terms.findIndex(p => p.id === term.id)
    state.terms = [
      ...state.terms.slice(0, index),
      term,
      ...state.terms.slice(index + 1)
    ]
  },
  setTermsNetworkStatus (state, status) {
    state.networkStatus = status
  }
}

export default {
  state,
  actions,
  mutations
}
