<template>
  <div class="Grid--wider">
    <div class="Grid__col Grid__col--3">
      <div class="Field">
        <div class="Field__label">
          Förfallodatum
        </div>
        {{ dueDate }}
      </div>
    </div>
    <div class="Grid__col Grid__col--3">
      <div class="Field">
        <div class="Field__label">
          Betalsätt
        </div>
        {{ invoice.payment_type }}
      </div>
    </div>
    <div class="Grid__col Grid__col--3">
      <div class="Field">
        <div class="Field__label">
          Status
        </div>
        <span class="Status Status--expires">Obetalad</span>
      </div>
    </div>
    <div class="Grid__col Grid__col--3 Links">
      <a href>Visa faktura</a>
    </div>
  </div>
</template>

<script>
import { invoiceDueDate } from '@/models/subscription'

export default {
  name: 'SubscriptionUnpaidInvoiceItem',
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  computed: {
    dueDate: function () {
      return invoiceDueDate(this.invoice)
    }
  }
}
</script>

<style lang="scss" scoped>

  a {
    @extend %focus-link;

    &:after {
      background: url('@/assets/images/link-arrow.svg');
    }
  }

  .Status {
    &--expires {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;

      &:before {
        background: url('@/assets/images/warning.svg');
      }
    }
  }

  @media screen and (min-width: $site_wrapper_width+0px) {
    .Links {
      padding-top: 8px;
    }
  }
</style>
