<template>
  <div
    v-if="activeSubscriptions.length"
    class=" Grid--wider Subscriptions-summary"
  >
    <div
      v-for="subscription in activeSubscriptions"
      :key="subscription.id"
      class="Grid__col Grid__col--4 Subscriptions-summary__item"
    >
      <div class="Subscriptions-summary__item__name">
        <router-link
          v-reset-scroll="true"
          :to="'/mina-kop/' + subscription.id"
        >
          {{ subscription.name }}
        </router-link>
      </div>
      <div v-if="!subscription.credit_ex" :class="statusClasses(subscription)">{{ status(subscription) }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { status, statusClasses } from '@/models/subscription'

export default {
  name: 'ProfileSubscriptionsSummary',
  computed: {
    ...mapGetters(['activeSubscriptions'])
  },
  methods: {
    statusClasses: function (subscription) {
      return statusClasses(subscription)
    },
    status: function (subscription) {
      return status(subscription)
    }
  }
}
</script>

<style lang="scss" scoped>
  .Subscriptions-summary {
    &__item {
      margin-bottom: $spacing/2;
    }
  }

  .Status {
    &--expires {

      &:before {
        background: url('@/assets/images/warning.svg');
      }
    }
  }
</style>
