<template>
  <div>
    <div v-if="subscription">
      <ContentHeader>
        <h1>{{ subscription.name }}</h1>
      </ContentHeader>
      <div class="Form-section">
        <router-link
          v-reset-scroll
          to="/mina-kop"
          class="Back-link"
        >
          Tillbaka till mina tjänster
        </router-link>
      </div>
      <div
        v-if="subscription.active"
        class="Form-section"
      >
        <h2>Din aktuella prenumeration</h2>
        <div class="Grid--wider">
          <div class="Grid__col Grid__col--3">
            <div class="Field">
              <div class="Field__label">
                Prenumerationsperiod
              </div>
              {{ duration }}
            </div>
          </div>
          <div class="Grid__col Grid__col--3">
            <div class="Field">
              <div class="Field__label">
                Ordernummer
              </div>
              {{ subscription.order_no }}
            </div>
          </div>
          <div
            v-if="subscription.delivery_address"
            class="Grid__col Grid__col--5"
          >
            <SubscriptionDeliveryAddressForm v-if="subscriptionEditArea === 'delivery_address'" />
            <div
              v-else
              class="Field"
            >
              <div class="Field__label">
                Leveransadress
              </div>
              {{ subscription.delivery_address.address_line1 }}
              <br v-if="subscription.delivery_address.address_line2"/>
              {{ subscription.delivery_address.address_line2 }}
              <br/>
              {{ subscription.delivery_address.zip_code }} {{ subscription.delivery_address.city }}
              <br/>
              <a
                href
                @click.prevent="setSubscriptionEditArea('delivery_address')"
              >
                Ändra leveransadress
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="unpaidInvoices.length"
        class="Form-section"
      >
        <h2>Obetalda fakturor</h2>
        <div
          v-for="invoice in unpaidInvoices"
          :key="invoice.document_id"
        >
          <SubscriptionUnpaidInvoiceItem :invoice="invoice" />
        </div>
      </div>
      <div
        v-if="paidInvoices.length"
        class="Form-section"
      >
        <h2>Betalningshistorik</h2>
        <div
          v-for="invoice in cappedPaidInvoices"
          :key="invoice.document_id"
          class="Payment"
        >
          <SubscriptionPaymentItem
            :subscription="subscription"
            :invoice="invoice"
          />
        </div>
        <a
          v-if="paidInvoices.length > cappedPaidInvoices.length"
          href
          @click.prevent="showAllPaidInvoices = true"
        >
          Visa fler betalningar
        </a>
      </div>
      <!--
      <div :class="renewSectionClasses">
        <h2>Förnya prenumerationen</h2>
        <p>
          {{ subscription.description }}
        </p>
        <div
          v-if="subscription.deal"
          class="Renew-section__deal"
        >
          <span>{{ subscription.deal }}</span>
        </div>
      </div>
      <div class="Form-section Form-section--no-border">
        <h2>Hantera licenser</h2>
        <SubscriptionLicensesForm />
      </div>
      -->
      <AppMenu :extraMenuAtBottom="true" />
    </div>
    <PreLoader v-else />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { duration } from '@/models/subscription'
import AppMenu from '@/components/AppMenu'
import PreLoader from '@/components/PreLoader'
import ContentHeader from '@/components/ContentHeader'
import RouteMixin from '../route-mixin'
import SubscriptionDeliveryAddressForm from './components/SubscriptionDeliveryAddressForm'
import SubscriptionUnpaidInvoiceItem from './components/SubscriptionUnpaidInvoiceItem'
import SubscriptionPaymentItem from './components/SubscriptionPaymentItem'
// import SubscriptionLicensesForm from './components/SubscriptionLicensesForm'

export default {
  name: 'SubscriptionRoute',
  components: { AppMenu, PreLoader, ContentHeader, SubscriptionDeliveryAddressForm, SubscriptionUnpaidInvoiceItem, SubscriptionPaymentItem },
  mixins: [RouteMixin],
  data () {
    return {
      showAllPaidInvoices: false
    }
  },
  computed: {
    ...mapState({
      subscriptionEditArea: state => state.subscriptionsStore.subscriptionEditArea
    }),
    ...mapGetters([
      'getSubscription'
    ]),
    subscription: function () {
      return this.getSubscription(this.$route.params.id)
    },
    duration: function () {
      return duration(this.subscription)
    },
    paidInvoices: function () {
      return this.subscription.invoices.filter(i => i.paid)
    },
    cappedPaidInvoices: function () {
      return this.paidInvoices.slice(0, 3)
    },
    unpaidInvoices: function () {
      return this.subscription.invoices.filter(i => !i.paid)
    },
    renewSectionClasses: function () {
      return {
        'Form-section': true,
        'Renew-section': true,
        'Renew-section--has-deal': this.subscription.deal
      }
    }
  },
  created () {
    this.requireCrmData('Subscriptions')
  },
  destroyed () {
    this.unsetSubscriptionEditArea()
  },
  methods: {
    ...mapActions([
      'setSubscriptionEditArea',
      'unsetSubscriptionEditArea'
    ])
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    @extend %title-M;
  }

  a {
    @extend %focus-link;

    &:after {
      background: url('@/assets/images/link-arrow.svg');
    }
  }

  .Back-link {
    @extend %focus-link--back;
    margin-top: $spacing/4;
  }

  .Form-section {
    padding-bottom: $spacing*1.25;
  }

  .Renew-section {

    &--has-deal {
      position: relative;

      &,
      .Form-section + & {
        padding-top: $spacing*2.125;

        @media screen and (min-width: $site_wrapper_width+0px) {
          padding-top: $spacing*1.5;
        }
      }
    }

    &__deal {
      @extend %wrapped-banner;
      left: -58px;
      top: 1px;
      width: 134px;

      @media screen and (min-width: $site_wrapper_width+0px) {
        left: -78px;
      }
    }
  }
</style>
