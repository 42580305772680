import { render, staticRenderFns } from "./ToggleArea.vue?vue&type=template&id=240bcda3&scoped=true&"
import script from "./ToggleArea.vue?vue&type=script&lang=js&"
export * from "./ToggleArea.vue?vue&type=script&lang=js&"
import style0 from "./ToggleArea.vue?vue&type=style&index=0&id=240bcda3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "240bcda3",
  null
  
)

export default component.exports