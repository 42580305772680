<template>
  <div>
    <ul class="Invoices-list">
      <li
        v-for="invoice in invoices"
        :key="invoice.id"
        class="Invoices-list__item"
      >
        <div class="Grid--wider">
          <div class="Grid__col Grid__col--4">
            <div class="Field">
              {{ invoice.title }}
            </div>
          </div>
          <div class="Grid__col Grid__col--2">
            <div class="Field">
              <div class="Field__label">Fakturadatum</div>
              {{ invoice.issueDate }}
            </div>
          </div>
          <div class="Grid__col Grid__col--2">
            <div class="Field">
              <div class="Field__label">Belopp</div>
              <span class="Field--highlight">{{ `${invoice.amount} kr` }}</span>
            </div>
          </div>
          <div class="Grid__col Grid__col--2">
            <div class="Field">
              <div class="Field__label">Status</div>
              <span class="Field--status">{{ invoice.status }}</span>
            </div>
          </div>
        </div>
        <button
          class="Invoices-list__download"
          @click.prevent="handleDownload(invoice.url)"
        ></button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'InvoicesList',
  props: {
    invoices: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleDownload (e, url) {
      const link = document.createElement('a')
      link.href = url
      link.download = 'file.pdf'
      link.dispatchEvent(new MouseEvent('click'))
    }
  }
}
</script>

<style lang="scss" scoped>
.Invoices-list {
  list-style-type: none;
  padding: 0;
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  h3 {
    @extend %form-label;
    margin: $spacing 0 $spacing/4 0;
  }
  &__item {
    line-height: 1;
    margin: 0;
    padding: $spacing 0;
    position: relative;
    border-bottom: 1px solid $border-color;
    &:last-child {
      border-bottom: none;
    }
  }
  &__download {
    position: absolute;
    right: 0;
    background: url("@/assets/images/download.svg");
    width: $spacing;
    height: $spacing;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .Field--highlight {
    color: $action-color;
  }
  .Field--status {
    font-style: italic;
  }
}
</style>
