<template>
  <div
    v-if="areaNotifications(area) && areaNotifications(area).length"
    class="Notifications"
  >
    <h2 class="Notifications__heading">
      Kort rubrik om notifikationer
    </h2>
    <ul :class="listClasses">
      <li
        v-for="notification in areaNotifications(area)"
        :key="notification.id"
        class="Notifications__item"
      >
        {{ notification.text }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AreaNotifications',
  props: {
    area: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'areaNotifications'
    ]),
    listClasses: function () {
      return {
        Notifications__list: true,
        'Notifications__list--single-item': this.areaNotifications(this.area).length === 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .Notifications {
    background: $notice-bg-color;
    margin: -$spacing 0 $spacing 0;
    padding: $spacing*0.75;

    &__heading {
      @extend %title-S;
    }

    &__list {
      &--single-item {
        list-style: none;
        padding-left: 0;
      }
    }

    &__item {
    }
  }
</style>
