<template>
  <div @click="openMenuOnSmallScreens">
    <slot></slot>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ContentHeader',
  methods: {
    ...mapActions([
      'openMenuOnSmallScreens'
    ])
  }
}
</script>

<style lang="scss" scoped>
  h1 {
    @extend %title-L;
    @extend %font-publico-bold;
    @extend %content-header;
    padding-bottom: 10px;
    margin-top: 8px;

    @media screen and (max-width: $site_wrapper_width+1px) {
      cursor: pointer;
      padding-left: $spacing;
      position: relative;

      &:before {
        content: '';
        background: url('@/assets/images/chevron-black.svg');
        height: 9px;
        left: 0;
        margin-top: -12px;
        position: absolute;
        top: 50%;
        transform: scale(1.5) rotate(90deg);
        width: 14px;
      }
    }
  }
</style>
