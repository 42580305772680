<template>
  <div :class="containerClasses">
    <div class="Offer-item__inner">
      <LinkOrText
        :href="offer.url"
        target="_blank"
        className="Offer-item__header"
      >
        <span class="Offer-item__header__inner">
          <span
            v-if="offer.type"
            class="Offer-item__type"
          >
            {{ offer.type }}
          </span>
          <span class="Offer-item__name">
            {{ offer.name }}
          </span>
          <span
            v-if="offer.yearly_price"
            class="Offer-item__price"
          >
            {{ offerYearlyPrice }} kr/år
          </span>
          <span
            v-if="bestPrice"
            class="Offer-item__price"
          >
            {{ bestPrice }}
          </span>
        </span>
      </LinkOrText>
      <div class="Offer-item__description">
        <p>
          {{ offer.description }}
        </p>
        <div
          v-if="expiresSoon"
          :class="statusClasses"
        >
          {{ status }}
        </div>
        <div
          v-if="offer.deal"
          class="Offer-item__deal"
        >
          {{ offer.deal }}
        </div>
      </div>
      <div class="Offer-item__actions">
        <div class="Offer-item__actions__inner">
          <router-link
            v-if="offer.publickey"
            v-reset-scroll="true"
            :to="checkoutRoute"
            class="Button Button--narrow"
          >
            Köp prenumeration
          </router-link>
          <div>
            <router-link
              v-reset-scroll="true"
              :to="offerRoute"
              class="Offer-item__link"
            >
              Visa detaljer
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import priceString from '@/services/price-string'
import { expiresSoon, status, statusClasses } from '@/models/offer'

import LinkOrText from '@/components/LinkOrText'

export default {
  name: 'OfferItem',
  components: { LinkOrText },
  props: {
    offer: {
      type: Object,
      required: true
    }
  },
  computed: {
    checkoutRoute: function () {
      return '/checkout/' + this.offer.publickey + '/'
    },
    offerRoute: function () {
      return '/mina-kop/' + this.offer.publickey
    },
    containerClasses: function () {
      return {
        'Offer-item': true,
        'Offer-item--expires-soon': this.expiresSoon
      }
    },
    bestPrice: function () {
      return priceString(this.offer.amount) + ' kr'
    },
    offerYearlyPrice: function () {
      return priceString(this.offer.yearly_price)
    },
    statusClasses: function () {
      return statusClasses(this.offer)
    },
    expiresSoon: function () {
      return expiresSoon(this.offer)
    },
    status: function () {
      return status(this.offer)
    }
  }

}
</script>

<style lang="scss" scoped>
  .Offer-item {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    margin-top: $spacing/2;
    overflow: hidden;

    &--expires-soon {
      background: $notice-bg-color;
    }

    & + .Offer-item {
      margin-top: $spacing;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    &__header {
      display: block;
      padding: 0 $spacing;

      &__inner {
        border-bottom: 1px solid $border-color;
        display: block;
        padding: $spacing 0;
      }

      @media screen and (min-width: $site_wrapper_width+0px) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &__inner {
          position: relative;
        }
      }
    }

    &__type {
      color: $focus-color;
      display: block;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      @media screen and (min-width: $site_wrapper_width+0px) {
        position: absolute;
        bottom: 100%;
      }
    }

    &__name {
      @extend %title-S;
      @extend %focus-link;
      @extend %focus-link--no-color;

      &:after {
        background: url('@/assets/images/link-arrow--black.svg');
        margin-top: -6px;
      }
    }

    &__price {
      display: block;
    }

    &__description {
      font-size: 14px;
      overflow: hidden;
      padding: $spacing $spacing*3;
      position: relative;
    }

    &__deal {
      @extend %banner;
    }

    &__actions {
      padding: $spacing;
      text-align: center;
    }

    &__link {
      @extend %focus-link;

      &:after {
        background: url('@/assets/images/link-arrow.svg');
      }
    }

    @media screen and (min-width: $site_wrapper_width) {
      &__inner {
        flex-direction: row;
        text-align: left;
      }

      &__header {
        border-right: 1px solid $border-color;
        flex: none;
        margin: $spacing 0;
        width: 220px + $spacing*2;

        &__inner {
          border-bottom: 0;
          padding: 0;
        }
      }

      &__description {
        border-bottom: 0;
        flex: auto;
        padding: $spacing $spacing $spacing $spacing*3;
      }

      &__actions {
        display: flex;
        flex: none;
        padding: $spacing $spacing $spacing 0;
        width: 200px + $spacing;

        &__inner {
          margin: auto;
        }
      }
    }
  }

  .Status {
    &--expires {

      &:before {
        background: url('@/assets/images/warning.svg');
      }
    }
  }
</style>
