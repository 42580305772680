const backUrlKey = 'client_back_url'

export const getQueryParam = (name) => {
  name = name.replace(/[[]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(window.location.href)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const setBackUrl = () => {
  const backUrl = getQueryParam(backUrlKey)
  if (backUrl) {
    sessionStorage.setItem(backUrlKey, backUrl)
  }
}

export const getBackUrl = () => {
  if (sessionStorage.getItem(backUrlKey)) {
    return sessionStorage.getItem(backUrlKey)
  }
  return getQueryParam(backUrlKey) || process.env.VUE_APP_BACK_URL
}

export const getBackUrlQuery = (params) => {
  const url = new URL(getBackUrl())
  for (const name in params) {
    url.searchParams.append(name, params[name])
  }
  return url.toString()
}
