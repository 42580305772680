<template>
  <div>
    <ContentHeader>
      <h1>Kontakt</h1>
    </ContentHeader>
    <AreaNotifications area="contact" />

    <p>Har du frågor kring abonnemang, digitala tjänster och fakturor kontaktar du <a href="https://chef.se/kundservice/">kundtjänst</a> genom <a href="mailto:kundtjanst@chef.se">kundtjanst@chef.se</a>. </p>
    <p>Är du medlem i Ledarna? <br/>Då kontaktar du istället Ledarnas medlemsservice på telefon 0200-87 11 11.</p>
    <p>Fler kontaktvägar till Chef och Chefakademin hittar du på sidan <a href="https://chef.se/chefakademin/kontakta-oss/">Kontakta oss</a>.</p>

    <div v-if="false">
      <h2>Vanliga frågor</h2>
      <ToggleArea :accordion="true">
        <template slot="trigger">
          Vanlig fråga 1
        </template>
        <template slot="content">
          <p>Svar på den här frågan.</p>
        </template>
      </ToggleArea>
      <ToggleArea :accordion="true">
        <template slot="trigger">
          Vanlig fråga 2
        </template>
        <template slot="content">
          <p>Svar på den här frågan.</p>
        </template>
      </ToggleArea>
    </div>
    <AppMenu :extraMenuAtBottom="true" />
  </div>
</template>

<script>
import AppMenu from '@/components/AppMenu'
import ContentHeader from '@/components/ContentHeader'
import AreaNotifications from '@/components/AreaNotifications'
import ToggleArea from '@/components/ToggleArea'

export default {
  name: 'ContactRoute',
  components: { AppMenu, ContentHeader, AreaNotifications, ToggleArea }
}
</script>

<style lang="scss" scoped>
  h2 {
    margin: $spacing*2 0 $spacing/2 0;
  }

  h3 {
    @extend %form-label;
    margin: $spacing 0 $spacing/4 0;
  }

  a {
    text-decoration: underline;

    &[href^="tel:"] {
      text-decoration: none;
    }
  }
</style>
