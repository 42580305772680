<template>
  <div>
    <div v-if="offers">
      <ContentHeader>
        <h1>Mina erbjudanden</h1>
      </ContentHeader>
      <AreaNotifications area="offers" />
      <p>
        Nedan ser du alla produkter och tjänster som Chef erbjuder. Lorem ipsum dolor sit amet, consectetur adipiscing elit. För att säga upp en prenumeration kontaktar du kundtjänst på <a href="tel:+46085412566">08-541 25 66</a> eller <a href="mailto:chef.prenservice.se">chef.prenservice.se</a>.
      </p>
      <div v-if="offers.length">
        <h2>
          Aktiva
        </h2>
        <div class="Offer-items">
          <OffersOfferItem
            v-for="offer in offers"
            :key="offer.publickey"
            :offer="offer"
          />
        </div>
      </div>
    </div>
    <PreLoader v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import PreLoader from '@/components/PreLoader'
import ContentHeader from '@/components/ContentHeader'
import AreaNotifications from '@/components/AreaNotifications'
import RouteMixin from '../route-mixin'
import OffersOfferItem from './components/OffersOfferItem'

export default {
  name: 'OffersRoute',
  components: { PreLoader, ContentHeader, AreaNotifications, OffersOfferItem },
  mixins: [RouteMixin],
  computed: {
    ...mapState({
      offers: state => state.offersStore.offers
    })
  },
  created () {
    this.requireCrmData('Offers')
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    @extend %title-S;
    margin-top: $spacing*2;
  }
</style>
