export default (amount, hideZeroDecimals = true) => {
  if (!amount || isNaN(amount)) {
    amount = 0
  } else {
    amount = parseFloat(amount)
  }
  amount = Math.round(amount * 100) / 100
  amount = amount += ''
  amount = amount.replace('.', ',')
  const parts = amount.split(',')
  const digits = parts[0].split('').reverse()
  let formatted = ''
  digits.forEach((digit, index) => {
    if (index > 0 && Math.floor(index % 3) === 0) {
      formatted += ' '
    }
    formatted += digit
  })
  formatted = formatted
    .split('')
    .reverse()
    .join('')
  let formattedDecimals = parts.length > 1 ? parts[1] : ''
  if (!hideZeroDecimals || formattedDecimals.length > 0) {
    while (formattedDecimals.length < 2) {
      formattedDecimals += '0'
    }
    return formatted + ',' + formattedDecimals
  }
  return formatted
}
