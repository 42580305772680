import store from '@/store'
import { NETWORK_STATUSES } from '@/services/http'

export default {
  methods: {
    requireCrmData: function (dataType) {
      const dataTypes = Array.isArray(dataType) ? dataType : [dataType]
      dataTypes.forEach((dataType) => {
        const dataStore = store.state[dataType.toLowerCase() + 'Store']
        if (dataStore && dataStore.networkStatus === NETWORK_STATUSES.WAITING) {
          store.dispatch('get' + dataType)
        }
      })
    }
  }
}
