<template>
  <div id="app" class="App">
    <div v-if="hasAccess">
      <header
        class="App-header"
      >
        <router-link
          to="/"
          class="App-header__logo"
        >
          Chef
        </router-link>
        <a
          :href="backUrl"
          class="App-header__close"
        >
          Tillbaka
        </a>
      </header>
      <div :class="appContentClasses">
        <div class="Grid__col Grid__col--2">
          <AppMenu />
        </div>
        <div v-if="hasMaintenance" class="App__Maintenance Grid__col Grid__col--6">
            <h2>Underhållsarbete pågår</h2>
            <div v-html="maintenance"></div>
        </div>
        <div v-else class="Grid__col Grid__col--10">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <PreLoader :delay="0" v-else-if="!oidcError" />

    <AppMessage />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { setBackUrl, getBackUrlQuery } from '@/services/uri'

import AppMenu from '@/components/AppMenu'
import AppMessage from '@/components/AppMessage'
import PreLoader from '@/components/PreLoader'

export default {
  name: 'App',
  components: { AppMenu, AppMessage, PreLoader },
  computed: {
    ...mapState({
      menuOpenOnSmallScreens: state => state.menuStore.menuOpenOnSmallScreens
    }),
    ...mapGetters([
      'oidcIsAuthenticated',
      'oidcError'
    ]),
    hasAccess: function () {
      return this.oidcIsAuthenticated || this.$route.meta.isPublic
    },
    hasMaintenance: function () {
      return !!process.env.VUE_APP_MAINTENANCE && this.$route.query.maintenance !== 'no'
    },
    maintenance: function () {
      return process.env.VUE_APP_MAINTENANCE
    },
    appContentClasses: function () {
      return {
        App__content: true,
        'App__content--menu-open-on-small-screens': this.menuOpenOnSmallScreens,
        Grid: true,
        'Grid--nowrap': true
      }
    },
    backUrl: function () {
      return getBackUrlQuery({ auth_refresh: 1 })
    }
  },
  methods: {
    ...mapActions([
      'showError'
    ])
  },
  watch: {
    oidcError: function (newOidcError, oldOidcError) {
      if (!this.hasAccess && newOidcError && !oldOidcError) {
        if (newOidcError === 'Network Error') {
          this.showError('Kontrollera din internetanslutning')
        } else {
          this.showError('Det gick inte att ansluta till Chefs inloggningsserver')
        }
      }
    }
  },
  created () {
    setBackUrl()
  }
}
</script>

<style lang="scss">
// @font-face {
//   font-family: 'Publico Headline Bold';
//   src: url('@/assets/fonts/publico-headline/Publico-Headline-Web-Bold.eot');
//   src: url('@/assets/fonts/publico-headline/Publico-Headline-Web-Bold.eot?#iefix') format('embedded-opentype'),
//        url('@/assets/fonts/publico-headline/Publico-Headline-Web-Bold.woff') format('woff'),
//        url('@/assets/fonts/publico-headline/Publico-Headline-Web-Bold.ttf') format('truetype'),
//        url('@/assets/fonts/publico-headline/Publico-Headline-Web-Bold.svg#publicoheadlinebold') format('svg');
//   font-weight: 500;
//   font-style: normal;
//   font-stretch: normal;
// }

.App {
  margin: 0 auto;
  max-width: $site_wrapper_width;
  padding: 0 $spacing $spacing*4 $spacing;

  @media screen and (max-width: $site_wrapper_width+1px) {
    // Small screen layout
    &__content {
      transform: translateX(-100vw);
      transition: transform 0.2s ease-out;

      & > .Grid__col {
        width: 100vw;
      }

      &--menu-open-on-small-screens {
        transform: translateX(0);
      }
    }
  }
}

.App-header {
  margin-bottom: $spacing;
  padding: $spacing 0;
  position: relative;

  &__logo {
    background: url('@/assets/images/logo.svg') no-repeat;
    background-size: 100%;
    color: transparent;
    display: block;
    overflow: hidden;
    text-indent: -999em;
    height: 85px;
    width: 85px;
  }

  &__close {
    background: url('@/assets/images/close.svg') no-repeat;
    height: 23px;
    margin-top: -12px;
    overflow: hidden;
    position: absolute;
    right: $spacing;
    text-indent: -999em;
    top: 50%;
    width: 23px;
  }
}
</style>
