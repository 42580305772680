import { NETWORK_STATUSES } from '@/services/http'
import * as api from '@/services/api'
import { router } from '@/routes/router'

const isCreditEx = (subscription) => {
  return !subscription.is_paid && (subscription.description || '').indexOf('överex') === -1
}

const isOverEx = (subscription) => {
  return !subscription.is_paid && (subscription.description || '').indexOf('överex') > -1
}

const state = {
  subscriptions: null,
  subscriptionEditArea: null,
  networkStatus: NETWORK_STATUSES.WAITING
}

const getters = {
  activeSubscriptions: (state) => {
    if (state.subscriptions) {
      return state.subscriptions.filter(p => p.active && !p.over_ex)
    }
    return []
  },
  inactiveSubscriptions: (state) => {
    if (state.subscriptions) {
      return state.subscriptions.filter(p => !p.active)
    }
    return []
  },
  getSubscription: (state) => {
    return (subscriptionId) => {
      if (state.subscriptions) {
        const subscription = state.subscriptions.find(subscription => subscription.id === subscriptionId)
        if (subscription) {
          return subscription
        } else {
          router.push('/404')
        }
      }
      return null
    }
  }
}

const actions = {
  getSubscriptions ({ commit }) {
    commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.GETTING)
    commit('setSubscriptions', null)
    api.getSubscriptions()
      .then(subscriptions => {
        commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setSubscriptions', subscriptions.map(subscription => ({
          ...subscription,
          credit_ex: isCreditEx(subscription),
          over_ex: isOverEx(subscription)
        })))
      })
      .catch((error) => {
        console.error(error)
        commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  updateSubscription ({ dispatch, commit }, subscription) {
    commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.SUBMITTING)
    api.updateSubscription(subscription)
      .then(subscriptionResponse => {
        commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setSubscription', subscriptionResponse)
        dispatch('showInfo', 'Ändringar sparade')
      })
      .catch((error) => {
        console.error(error)
        commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  addSubscriptionLicense ({ dispatch, commit }, data) {
    commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.SUBMITTING)
    const newId = parseInt(Math.random() * 10000, 10) // TEMP remove this with real api
    const subscriptionData = {
      ...data.subscription,
      licenses: [
        ...data.subscription.licenses,
        {
          id: newId,
          email: data.email,
          status: 'pending'
        }
      ]
    }
    api.updateSubscription(subscriptionData)
      .then(subscriptionResponse => {
        commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setSubscription', subscriptionResponse)
        dispatch('showInfo', 'Licensen är tillagd')
      })
      .catch((error) => {
        console.error(error)
        commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  removeSubscriptionLicense ({ dispatch, commit }, data) {
    commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.SUBMITTING)
    const index = data.subscription.licenses.findIndex(l => l.id === data.license.id)
    const subscriptionData = {
      ...data.subscription,
      licenses: [
        ...data.subscription.licenses.slice(0, index),
        ...data.subscription.licenses.slice(index + 1)
      ]
    }
    api.updateSubscription(subscriptionData)
      .then(subscriptionResponse => {
        commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setSubscription', subscriptionResponse)
        dispatch('showInfo', 'Licensen är borttagen')
      })
      .catch((error) => {
        console.error(error)
        commit('setSubscriptionNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  setSubscriptionEditArea ({ commit }, areaToEdit) {
    commit('setSubscriptionEditArea', areaToEdit)
  },
  unsetSubscriptionEditArea ({ commit }) {
    commit('setSubscriptionEditArea', null)
  }
}

const mutations = {
  setSubscriptions (state, subscriptions) {
    state.subscriptions = subscriptions
  },
  setSubscription (state, subscription) {
    const index = state.subscriptions.findIndex(p => {
      return p.id === subscription.id
    })
    state.subscriptions = [
      ...state.subscriptions.slice(0, index),
      subscription,
      ...state.subscriptions.slice(index + 1)
    ]
    state.subscriptionEditArea = null
  },
  setSubscriptionEditArea (state, areaToEdit) {
    state.subscriptionEditArea = areaToEdit
  },
  setSubscriptionNetworkStatus (state, status) {
    state.networkStatus = status
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
