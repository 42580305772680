<template>
  <div>
    <PapertonInfo v-if="showInfo" />
    <PreLoader v-else />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import PreLoader from '@/components/PreLoader'
import PapertonInfo from './components/PapertonInfo'

export default {
  name: 'PapertonRoute',
  components: { PreLoader, PapertonInfo },
  computed: {
    ...mapState({
      showInfo: state => state.papertonStore.showInfo
    })
  },
  methods: {
    ...mapActions([
      'goToPapertonIfUserHasAccess'
    ])
  },
  created () {
    this.goToPapertonIfUserHasAccess()
  }
}
</script>
