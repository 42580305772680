<template>
  <div v-if="rendered">
    <transition name="fade">
      <div
        v-if="visible"
        class="Confirm-dialog"
      >
        <div
          class="Confirm-dialog__inner"
          :style="innerStyles"
        >
          <p>
            {{ message }}
          </p>
          <div class="Confirm-dialog__actions">
            <button
              type="button"
              class="Button Button--narrow Button--secondary"
              @click="close"
            >
              Avbryt
            </button>
            <button
              type="button"
              class="Button Button--narrow"
              @click="confirm"
            >
              {{ confirmText }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AppConfirmDialog',
  props: {
    message: {
      type: String,
      default: 'Är du säker?'
    },
    confirmText: {
      type: String,
      default: 'OK'
    },
    onConfirm: {
      type: Function
    },
    onConfirmArg: {
      type: [Number, String, Object, Array]
    }
  },
  data () {
    return {
      rendered: true,
      visible: false
    }
  },
  computed: {
    innerStyles: function () {
      return {
        top: window.pageYOffset + 72 + 'px'
      }
    }
  },
  mounted: function () {
    this.visible = true
  },
  methods: {
    close: function () {
      this.visible = false
      setTimeout(() => {
        this.rendered = false
      }, 200)
    },
    confirm: function () {
      if (this.onConfirm) {
        if (this.onConfirmArg) {
          this.onConfirm(this.onConfirmArg)
        } else {
          this.onConfirm()
        }
      }
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
  .Confirm-dialog {
    background: rgba(0, 0, 0, 0.6);
    font-family: $fontfamily;
    font-size: $fontsize;
    min-height: 100vh;
    transition: opacity 0.2s ease-in-out;
    transition-delay: 0.5s;
    text-align: center;
    padding-top: $spacing*4;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (max-width: $site_wrapper_width+1px) {
      // Fix for translate (small screen menu) resetting fixed context
      left: 100vw;
      padding-left: $spacing;
      padding-right: $spacing;
      right: -100vw;
      top: -108px;
    }

    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }

    &__inner {
      background: white;
      border: 1px solid $border-color;
      display: block;
      font-size: 14;
      margin: 0 auto;
      max-width: 400px;
      padding: $spacing;

      @media screen and (max-width: $site_wrapper_width+1px) {
        // Fix for translate (small screen menu) resetting fixed context
        position: absolute;
      }

      @media screen and (max-width: 449px) {
        // Fix for translate (small screen menu) resetting fixed context
        left: $spacing;
        right: $spacing;
      }
    }

    &__actions {
      margin-top: $spacing;
      text-align: center;

      button {
        margin: 0 $spacing/4;
      }
    }
  }
</style>
