import store from '@/store'

export function onerror (error, options) {
  const status = error.status === 0 && navigator.onLine ? 500 : error.status
  switch (status) {
    case 0:
      store.dispatch('showError', 'Kontrollera din internetanslutning')
      break
    case 400:
      // Should we show api validation error messages?
      store.dispatch('showError', 'Det gick inte att slutföra din begäran')
      break
    case 401:
    case 403:
      store.dispatch('showError', 'Tillträde nekat')
      break
    case 404:
      store.dispatch('showError', 'Resursen hittades inte')
      break
    default:
      store.dispatch('showError', 'Ett oväntat fel inträffade')
      break
  }
}
