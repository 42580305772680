<template>
  <a
    :href="href"
    :class="classes"
    :target="target"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'LinkOrText',
  props: {
    href: {
      type: String
    },
    target: {
      type: String
    },
    className: {
      type: String
    }
  },
  computed: {
    classes: function () {
      const classes = {
        'Link-or-text': true,
        'Link-or-text--text': !this.href
      }
      if (this.className) {
        classes[this.className] = true
      }
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
  .Link-or-text {
    &--text {
      @extend %nolink;
    }
  }
</style>
