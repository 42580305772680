import { NETWORK_STATUSES } from '@/services/http'
import * as api from '@/services/api'
import { ecommerceTrackPurchase } from '@/services/qvalia-checkout'
import { router } from '@/routes/router'

const state = {
  checkout: null,
  order: null,
  defaultEmail: null,
  productCategory: null,
  referrer: null,
  networkStatus: NETWORK_STATUSES.WAITING
}

const getters = {
  getCheckout: (state) => {
    return (offerId) => {
      if (state.checkout && state.checkout.offer_id === offerId) {
        return state.checkout
      }
      return null
    }
  }
}

const actions = {
  createOrder ({ state, commit }, payload) {
    if (!payload.order) {
      return
    }
    api.createOrder({
      widget_session_token: payload.signature,
      ...payload.order,
      referrer: state.referrer || { url: '/' }
    })
      .then(order => {
        commit('setCheckoutNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setOrder', order)
        window.dispatchEvent(new CustomEvent('qvalia_checkout_order_success', { detail: order }))
        ecommerceTrackPurchase(order.order_number, order.products, order.products[0].name)
      })
      .catch((error) => {
        console.error(error)
        window.dispatchEvent(new CustomEvent('qvalia_checkout_order_failed', { detail: error }))
        commit('setCheckoutNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  redirectToCheckout (context, { payload }) {
    if (payload.email) {
      context.commit('setDefaultEmail', payload.email)
    }
    if (payload.productCategory) {
      context.commit('setProductCategory', payload.productCategory)
    }
    if (payload.referrer) {
      context.commit('setReferrer', payload.referrer)
    }
    if (payload.offer_id) {
      router.push('/checkout/' + payload.offer_id)
    } else {
      console.error('offer_id missing in checkout redirector')
      context.dispatch('showError', 'Erbjudandet saknas')
      context.commit('setCheckoutNetworkStatus', NETWORK_STATUSES.ERROR)
    }
  },
  getCheckout ({ commit }, payload) {
    console.log('getCheckout', { payload })
    commit('setCheckoutNetworkStatus', NETWORK_STATUSES.GETTING)
    commit('setCheckout', null)
    api.getCheckout(payload.offer_id)
      .then(checkout => {
        commit('setCheckoutNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setCheckout', checkout)
      })
      .catch((error) => {
        console.error(error)
        commit('setCheckoutNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  clearCheckout ({ commit }) {
    commit('setCheckout', null)
  }
}

const mutations = {
  setCheckout (state, checkout) {
    state.order = null
    state.checkout = checkout
  },
  setCheckoutNetworkStatus (state, status) {
    state.networkStatus = status
  },
  setOrder (state, order) {
    state.order = order
  },
  setDefaultEmail (state, email) {
    state.defaultEmail = email
  },
  setProductCategory (state, productCategory) {
    state.productCategory = productCategory
  },
  setReferrer (state, referrer) {
    state.referrer = referrer
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
