<template>
  <div>
    <form novalidate="true" @submit.prevent="submitForm" v-if="formData">
      <div class="Grid--wider">
        <div class="Grid__col Grid__col--4">
          <TextInput
            v-model="formData.ledarnano"
            type="text"
            label="Ev. medlemsnummer Ledarna"
            placeholder="Ange ev medlemsnr. här"
            :validation="$v.formData.ledarnano"
            :tooltip="profileTooltips.ledarnano"
            @touch="touchInput('ledarnano')"
          />
        </div>
        <!--
        <div class="Grid__col Grid__col--4">
          <TextInput
            v-model="formData.postalcode"
            type="text"
            label="Postnummer"
            placeholder="Ange postnummer"
            :validation="$v.formData.postalcode"
            :tooltip="profileTooltips.postalcode"
            @touch="touchInput('postalcode')"
          />
        </div>
        -->
      </div>
      <div v-if="!!formData.ledarnano" class="Grid--wider">
        <div class="Grid__col Grid__col--7">
          <CheckboxInput
            text="Jag är införstådd med att uppgiften om mitt medlemskap i Ledarna är en känslig uppgift enligt dataskyddslagstiftningen och samtycker till att Chefakademin i Stockholm AB behandlar dessa uppgifter om mig. Chefakademin i Stockholm ABs behandling av uppgifterna sker i syfte att erbjuda användaren rabatter som erbjuds Ledarnas medlemmar. Jag är också införstådd med min rätt att återkalla samtycket."
            v-model="acceptLedarnaTerms"
          />
        </div>
      </div>

      <SubmitButton
        :submitting="submitting"
      >
        Spara
      </SubmitButton>

      <a
        href
        class="Cancel-link"
        @click.prevent="unsetProfileEditArea()"
      >
        Avbryt
      </a>

      <div
        v-if="$v.$error"
        class="Form-error"
      >
        Formuläret innehåller fel.
      </div>
      <div
        v-else-if="showAcceptLedarnaTermsError"
        class="Form-error"
      >
        Du har fyllt i ett medlemsnummer för Ledarna. Du måste därför ange samtycke för hantering av uppgiften om ditt medlemskap.
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { NETWORK_STATUSES } from '@/services/http'
import { membershipValidations, profileTooltips } from '@/models/profile'
import FormMixin from '@/components/form/FormMixin'
import TextInput from '@/components/form/TextInput'
import SubmitButton from '@/components/form/SubmitButton'
import CheckboxInput from '@/components/form/CheckboxInput'

export default {
  name: 'ProfileMemberForm',
  components: { TextInput, SubmitButton, CheckboxInput },
  mixins: [FormMixin],
  validations () {
    return {
      // formData: this.validatePostalcode ? membershipValidationsWithPostalcode : membershipValidations
      formData: membershipValidations
    }
  },
  data: function () {
    return {
      acceptLedarnaTerms: false,
      showAcceptLedarnaTermsError: false,
      validatePostalcode: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profileStore.profile,
      profileNetworkStatus: state => state.profileStore.networkStatus
    }),
    profileTooltips: function () {
      return profileTooltips
    },
    submitting: function () {
      return this.profileNetworkStatus === NETWORK_STATUSES.SUBMITTING
    },
    member: function () {
      return this.formData?.ledarnano
    }
  },
  watch: {
    member: function (newVal, oldVal) {
      if (newVal && newVal.length) {
        this.validatePostalcode = true
      } else {
        this.validatePostalcode = false
      }
    }
  },
  mounted () {
    this.initForm(this.profile)
    this.validatePostalcode = !!this.profile.ledarnano
  },
  methods: {
    ...mapActions([
      'updateProfile',
      'unsetProfileEditArea'
    ]),
    submitForm: function () {
      this.showAcceptLedarnaTermsError = false
      if (!this.formIsValid()) {
        return
      }
      if (this.formData.ledarnano && !this.acceptLedarnaTerms) {
        this.showAcceptLedarnaTermsError = true
        return
      }
      this.updateProfile(this.formData)
    }
  }
}
</script>
