<template>
  <div>
    <ContentHeader>
      <h1>Fakturor</h1>
    </ContentHeader>
    <p>
      Lorem ipsum...
    </p>

    <h2>Faktura</h2>

    <InvoicesList :invoices="invoices" />
    <AppMenu :extraMenuAtBottom="true" />

  </div>
</template>

<script>
import AppMenu from '@/components/AppMenu'
import ContentHeader from '@/components/ContentHeader'
import InvoicesList from './components/InvoicesList'

const mockedData = [
  {
    id: 1,
    title: 'Tidningen Chef 12 månader',
    issueDate: '2022-01-10',
    amount: 799,
    status: 'Betald',
    url: ''
  },
  {
    id: 2,
    title: 'Tidningen Chef 6 månader',
    issueDate: '2022-01-12',
    amount: 499,
    status: 'Betald',
    url: ''
  },
  {
    id: 3,
    title: 'Tidningen Chef 3 månader',
    issueDate: '2022-01-12',
    amount: 499,
    status: 'Betald',
    url: ''
  },
  {
    id: 4,
    title: 'Tidningen Chef 12 månader',
    issueDate: '2022-01-12',
    amount: 499,
    status: 'Betald',
    url: ''
  },
  {
    id: 5,
    title: 'Tidningen Chef 6 månader',
    issueDate: '2022-01-12',
    amount: 499,
    status: 'Betald',
    url: ''
  },
  {
    id: 6,
    title: 'Tidningen Chef 3 månader',
    issueDate: '2022-01-12',
    amount: 499,
    status: 'Betald',
    url: ''
  },
  {
    id: 7,
    title: 'Tidningen Chef 12 månader',
    issueDate: '2022-01-12',
    amount: 499,
    status: 'Betald',
    url: ''
  },
  {
    id: 8,
    title: 'Tidningen Chef 6 månader',
    issueDate: '2022-01-12',
    amount: 499,
    status: 'Betald',
    url: ''
  },
  {
    id: 9,
    title: 'Tidningen Chef 3 månader',
    issueDate: '2022-01-12',
    amount: 499,
    status: 'Betald',
    url: ''
  }

]
export default {
  name: 'InvoicesRoute',
  components: { ContentHeader, InvoicesList, AppMenu },
  data () {
    return {
      invoices: mockedData
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    margin: $spacing*2 0 $spacing/2 0;
  }

</style>
