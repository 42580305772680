<template>
  <div>
    <form novalidate="true" @submit.prevent="submitForm" v-if="formData">
      <div class="Grid--wider">
        <div class="Grid__col Grid__col--4">
          <TextInput
            v-model="formData.firstname"
            type="text"
            label="Förnamn"
            placeholder="Ange förnamn"
            :validation="$v.formData.firstname"
            @touch="touchInput('firstname')"
          />
        </div>
        <div class="Grid__col Grid__col--4">
          <TextInput
            v-model="formData.lastname"
            type="text"
            label="Efternamn"
            placeholder="Ange efternamn"
            :validation="$v.formData.lastname"
            @touch="touchInput('lastname')"
            />
        </div>
      </div>

      <SubmitButton
        :submitting="submitting"
      >
        Spara
      </SubmitButton>

      <a
        href
        class="Cancel-link"
        @click.prevent="unsetProfileEditArea()"
      >
        Avbryt
      </a>

      <div
        v-if="$v.$error"
        class="Form-error"
      >
        Formuläret innehåller fel.
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { NETWORK_STATUSES } from '@/services/http'
import { accountValidations, profileTooltips } from '@/models/profile'
import FormMixin from '@/components/form/FormMixin'
import TextInput from '@/components/form/TextInput'
import SubmitButton from '@/components/form/SubmitButton'

export default {
  name: 'ProfileAccountForm',
  components: { TextInput, SubmitButton },
  mixins: [FormMixin],
  validations: {
    formData: accountValidations
  },
  computed: {
    ...mapState({
      profile: state => state.profileStore.profile,
      profileNetworkStatus: state => state.profileStore.networkStatus
    }),
    profileTooltips: function () {
      return profileTooltips
    },
    submitting: function () {
      return this.profileNetworkStatus === NETWORK_STATUSES.SUBMITTING
    },
    titleOptions: function () {
      return [
        {
          text: 'Välj intresseområden',
          value: null
        },
        ...this.formData.options.title
      ]
    },
    branchOptions: function () {
      return [
        {
          text: 'Välj branch',
          value: null
        },
        ...this.formData.options.branch
      ]
    },
    customertypeOptions: function () {
      return [
        {
          text: 'Välj sektor',
          value: null
        },
        ...this.formData.options.customertype
      ]
    }
  },
  mounted () {
    this.initForm(this.profile)
  },
  methods: {
    ...mapActions([
      'updateProfile',
      'unsetProfileEditArea'
    ]),
    submitForm: function () {
      if (!this.formIsValid()) {
        return
      }
      this.updateProfile(this.formData)
    }
  }
}
</script>
