import { required, email, minLength, maxLength, numeric } from 'vuelidate/lib/validators'
import { isValidOrgNo } from 'se-org-no'
import { zipCode } from '@/services/custom-validators'

export const profileTooltips = {
  emailaddress2: 'Förutom din primära e-postadress, som kanske är din jobb-mejl, kan det vara bra att ange en alternativ adress ifall du skulle byta jobb.',
  title: 'Info om yrke',
  branch: 'Info om bransch',
  sector: 'Info om sektor',
  ledarnano: 'Ange ditt medlemsnummer i Ledarna för att få tillgång till medlemstjänster på chef.se',
  postalcode: 'Ange ditt postnummer här om du har medlemskap hos Ledarna'
}

export const passwordValidations = {
  email: {
    required,
    email
  },
  old_password: {
    required
  },
  password: {
    required,
    minLength: minLength(4)
  }
}

export const accountValidations = {
  firstname: {
    required
  },
  lastname: {
    required
  },
  emailaddress2: {
    email
  }
}

export const membershipValidations = {
  ledarnano: {
    numeric
  }
}
export const membershipValidationsWithPostalcode = {
  ...membershipValidations,
  postalcode: {
    required,
    minLength: minLength(5),
    maxLength: maxLength(5),
    zipCode
  }
}

export const paymentValidations = {
  account: {
    orgno: {
      isValidOrgNo: (input) => !input || isValidOrgNo(input)
    }
  }
}

export const paymentValidationsWithAddress = {
  ...paymentValidations,
  account: {
    ...paymentValidations.account,
    address_line1: {
      required
    },
    postalcode: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(5),
      zipCode
    },
    city: {
      required
    }
  }
}
