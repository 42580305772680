import { NETWORK_STATUSES } from '@/services/http'
import * as api from '@/services/api'

const state = {
  offers: null,
  networkStatus: NETWORK_STATUSES.WAITING
}

const actions = {
  getOffers ({ commit }) {
    commit('setOfferNetworkStatus', NETWORK_STATUSES.GETTING)
    commit('setOffers', null)
    api.getOffers()
      .then(offers => {
        commit('setOfferNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setOffers', offers)
      })
      .catch((error) => {
        console.error(error)
        commit('setOfferNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  setOfferEditArea ({ commit }, areaToEdit) {
    commit('setOfferEditArea', areaToEdit)
  },
  unsetOfferEditArea ({ commit }) {
    commit('setOfferEditArea', null)
  }
}

const mutations = {
  setOffers (state, offers) {
    state.offers = offers
  },
  setOfferNetworkStatus (state, status) {
    state.networkStatus = status
  }
}

export default {
  state,
  actions,
  mutations
}
