<template>
  <div :class="containerClasses">
    <div class="Subscription-item__inner">
      <LinkOrText
        :href="subscription.url"
        target="_blank"
        className="Subscription-item__header"
      >
        <span class="Subscription-item__header__inner">
          <span
            v-if="subscription.type"
            class="Subscription-item__type"
          >
            {{ subscription.type }}
          </span>
          <span class="Subscription-item__name">
            {{ subscription.name }}
          </span>
          <span
            v-if="subscription.yearly_price"
            class="Subscription-item__price"
          >
            {{ subscriptionYearlyPrice }} kr/år
          </span>
        </span>
      </LinkOrText>
      <div class="Subscription-item__description">
        <p>
          {{ subscription.description }}
        </p>
        <div
          v-if="expiresSoon && !subscription.credit_ex"
          :class="statusClasses"
        >
          {{ status }}
        </div>
        <div
          v-if="subscription.deal"
          class="Subscription-item__deal"
        >
          {{ subscription.deal }}
        </div>
      </div>
      <div class="Subscription-item__actions">
        <div class="Subscription-item__actions__inner">
          <router-link
            v-if="false && subscription.renewable"
            v-reset-scroll="true"
            :to="checkoutRenewableRoute"
            class="Button Button--narrow"
          >
            Förnya prenumeration
          </router-link>
          <router-link
            v-if="false && subscription.buyable"
            v-reset-scroll="true"
            :to="checkoutBuyableRoute"
            class="Button Button--narrow"
          >
            Köp prenumeration
          </router-link>
          <div v-if="subscription.active">
            <router-link
              v-reset-scroll="true"
              :to="subscriptionRoute"
              class="Subscription-item__link"
            >
              Visa detaljer
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import priceString from '@/services/price-string'
import { expiresSoon, status, statusClasses } from '@/models/subscription'

import LinkOrText from '@/components/LinkOrText'

export default {
  name: 'SubscriptionItem',
  components: { LinkOrText },
  props: {
    subscription: {
      type: Object,
      required: true
    }
  },
  computed: {
    checkoutBuyableRoute: function () {
      return '/renewal/' + this.subscription.buyable.publickey + '/' + this.subscription.subscription_id
    },
    checkoutRenewableRoute: function () {
      return '/renewal/' + this.subscription.renewable.publickey + '/' + this.subscription.subscription_id
    },
    subscriptionRoute: function () {
      return '/mina-kop/' + this.subscription.id
    },
    containerClasses: function () {
      return {
        'Subscription-item': true,
        'Subscription-item--expires-soon': this.expiresSoon
      }
    },
    subscriptionYearlyPrice: function () {
      return priceString(this.subscription.yearly_price)
    },
    statusClasses: function () {
      return statusClasses(this.subscription)
    },
    expiresSoon: function () {
      return expiresSoon(this.subscription)
    },
    status: function () {
      return status(this.subscription)
    }
  }

}
</script>

<style lang="scss" scoped>
  .Subscription-item {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    margin-top: $spacing/2;
    overflow: hidden;

    &--expires-soon {
      background: $notice-bg-color;
    }

    & + .Subscription-item {
      margin-top: $spacing;
    }

    &__inner {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    &__header {
      display: block;
      padding: 0 $spacing;

      &__inner {
        border-bottom: 1px solid $border-color;
        display: block;
        padding: $spacing 0;
      }

      @media screen and (min-width: $site_wrapper_width+0px) {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        &__inner {
          position: relative;
        }
      }
    }

    &__type {
      color: $focus-color;
      display: block;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;

      @media screen and (min-width: $site_wrapper_width+0px) {
        position: absolute;
        bottom: 100%;
      }
    }

    &__name {
      @extend %title-S;
      @extend %focus-link;
      @extend %focus-link--no-color;

      &:after {
        background: url('@/assets/images/link-arrow--black.svg');
        margin-top: -6px;
      }
    }

    &__price {
      display: block;
    }

    &__description {
      font-size: 14px;
      overflow: hidden;
      padding: $spacing $spacing*3;
      position: relative;
    }

    &__deal {
      @extend %banner;
    }

    &__actions {
      padding: $spacing;
      text-align: center;
    }

    &__link {
      @extend %focus-link;

      &:after {
        background: url('@/assets/images/link-arrow.svg');
      }
    }

    @media screen and (min-width: $site_wrapper_width) {
      &__inner {
        flex-direction: row;
        text-align: left;
      }

      &__header {
        border-right: 1px solid $border-color;
        flex: none;
        margin: $spacing 0;
        width: 220px + $spacing*2;

        &__inner {
          border-bottom: 0;
          padding: 0;
        }
      }

      &__description {
        border-bottom: 0;
        flex: auto;
        padding: $spacing $spacing $spacing $spacing*3;
      }

      &__actions {
        display: flex;
        flex: none;
        padding: $spacing $spacing $spacing 0;
        width: 200px + $spacing;

        &__inner {
          margin: auto;
        }
      }
    }
  }

  .Status {
    &--expires {

      &:before {
        background: url('@/assets/images/warning.svg');
      }
    }
  }
</style>
