export function trackCheckoutStep1 (products, registeredAccount) {
  trackCheckoutStep(1, registeredAccount ? 'Register' : 'Login', products)
}

export function trackCheckoutStep2 () {
  trackCheckoutStep(2, 'Company')
}

export function trackCheckoutStep2Update (isPerson = false) {
  trackCheckoutStepUpdate(2, isPerson ? 'Individual' : 'Company')
}

export function trackCheckoutStep3 (isPerson) {
  trackCheckoutStep(3, isPerson ? 'Address' : 'Company address')
}

export function trackCheckoutStep3Update (isPerson) {
  trackCheckoutStepUpdate(3, isPerson ? 'Address' : 'Company address')
}

export function trackCheckoutStep4 (paymentMethod) {
  trackCheckoutStep(4, paymentMethod)
}

export function trackCheckoutStep4Update (paymentMethod) {
  trackCheckoutStepUpdate(4, paymentMethod)
}

export function trackPurchase (orderNumber, products) {
  track({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: orderNumber,
          revenue: products.reduce((revenue, product) => {
            return revenue + product.price + product.tax
          }, 0),
          tax: products.reduce((tax, product) => {
            return tax + product.tax
          }, 0)
        },
        products: products
      }
    }
  })
}

function trackCheckoutStep (step, option, products = null) {
  const trackingObject = {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: step, option: option }
      }
    },
    eventCallback: function () {}
  }
  if (products) {
    trackingObject.ecommerce.checkout.products = products
  }
  track(trackingObject)
}

function trackCheckoutStepUpdate (step, option) {
  track({
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: { step: step, option: option }
      }
    }
  })
}

function track (trackingObject) {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(trackingObject)
  } else {
    console.log('Track ecommerce, but there is no GTM', JSON.stringify(trackingObject))
  }
}
