export const NETWORK_STATUSES = {
  WAITING: 'waiting',
  GETTING: 'getting',
  SUBMITTING: 'submitting',
  SUCCESS: 'success',
  ERROR: 'error'
}

export const get = function (options) {
  return makeRequest('get', options)
}

export const post = function (options) {
  return makeRequest('post', options)
}

export const put = function (options) {
  return makeRequest('put', options)
}

export const del = function (options) {
  return makeRequest('delete', options)
}

function getFormData (data) {
  const formData = new FormData()
  Object.keys(data).forEach((key) => {
    formData.append(key, data[key])
  })
  return formData
}

function makeRequest (method, options) {
  const url = options.url
  const formData = options.json ? JSON.stringify(options.json) : options.data ? getFormData(options.data) : null
  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()
    xhr.open(method, url)
    xhr.withCredentials = true
    if (options.token) {
      xhr.setRequestHeader('Authorization', 'Bearer ' + options.token)
    }
    if (options.json) {
      xhr.setRequestHeader('Content-Type', 'application/json')
    }
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response)
      } else {
        const error = {
          status: this.status,
          statusText: xhr.statusText
        }
        if (options.onerror) {
          options.onerror(error)
        }
        reject(new Error('HTTP ' + error.status + ' error. ' + error.statusText))
      }
    }
    xhr.onerror = function () {
      const error = {
        status: this.status,
        statusText: xhr.statusText
      }
      if (options.onerror) {
        options.onerror(error)
      }
      reject(new Error('HTTP ' + error.status + ' error. ' + error.statusText))
    }
    if (formData) {
      xhr.send(formData)
    } else {
      xhr.send()
    }
  })
}
