<template>
  <div>
    <ContentHeader>
      <h1>Läs Chef digitalt</h1>
    </ContentHeader>
    <div>
      <h3>Köp lösnummer eller prenumerera digitalt</h3>
      <p>– <a href="https://itunes.apple.com/us/app/tidningen-chef/id561055659?mt=8" target="_blank">Ladda ner Chefs app för iPhone/iPad</a>.</p>
      <p>– <a href="https://play.google.com/store/apps/details?id=com.paperton.wl.tidningenchef&amp;feature=search_result#?t=W251bGwsMSwxLDEsImNvbS5wYXBlcnRvbi53bC50aWRuaW5nZW5jaGVmIl0." target="_blank">Ladda ner Chefs app för Androidbaserade telefoner eller surfplattor</a>.</p>
      <h3>Prenumerant på papperstidningen?<br />
      Så här låser du upp din digitala version</h3>
      <p>1) Ladda först ner Chef-appen via någon av ovanstående länkar.</p>
      <p>2) Tryck på ”Prenumerera nu”, därefter ”Pappersprenumerant?”.</p>
    </div>
  </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'

export default {
  name: 'PapertonInfo',
  components: { ContentHeader }
}
</script>
