export function gaPageView (url) {
  if (typeof window.ga !== 'undefined') {
    if (!url) {
      url = '/'
    }
    window.ga('send', 'pageview', url)
  }
}

export function gaEvent (eventCategory, eventAction, eventLabel) {
  if (typeof window.ga !== 'undefined') {
    window.ga('send', 'event', eventCategory, eventAction, eventLabel)
  } else if (typeof window.dataLayer !== 'undefined' && typeof window.dataLayer.push === 'function') {
    window.dataLayer.push({
      event: eventAction,
      eventCategory: eventCategory,
      eventLabel: eventLabel
    })
  }
}
