import Vue from 'vue'
import Router from 'vue-router'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import ProfileRoute from './profile/ProfileRoute'
import OffersRoute from './offers/OffersRoute'
import SubscriptionsRoute from './subscriptions/SubscriptionsRoute'
import SubscriptionRoute from './subscriptions/SubscriptionRoute'
import NewslettersRoute from './newsletters/NewslettersRoute'
import ContactRoute from './contact/ContactRoute'
import CheckoutRedirector from './checkout/CheckoutRedirector'
import CheckoutSuccessRoute from './checkout/CheckoutSuccessRoute'
import CheckoutRoute from './checkout/CheckoutRoute'
import FileNotFoundRoute from './error/FileNotFoundRoute'
import SsoSignInCallback from './sso-callbacks/SsoSignInCallback'
import SsoSignOutCallback from './sso-callbacks/SsoSignOutCallback'
import PapertonRoute from './paperton/PapertonRoute'
import InvoicesRoute from './invoices/InvoicesRoute'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Chef Mina sidor'
    },
    component: ProfileRoute
  },
  {
    path: '/min-profil',
    name: 'min-profil',
    meta: {
      title: 'Min profil | Chef Mina sidor'
    },
    component: ProfileRoute
  },
  {
    path: '/mina-erbjudanden',
    name: 'mina-erbjudanden',
    meta: {
      title: 'Mina erbjudanden | Chef Mina sidor'
    },
    component: OffersRoute
  },
  {
    path: '/mina-kop',
    name: 'mina-kop',
    meta: {
      title: 'Mina köp | Chef Mina sidor'
    },
    component: SubscriptionsRoute
  },
  {
    path: '/mina-kop/:id',
    name: 'mitt-kop',
    meta: {
      title: 'Mitt köp | Chef Mina sidor'
    },
    component: SubscriptionRoute
  },
  {
    path: '/mina-nyhetsbrev',
    name: 'mina-nyhetsbrev',
    meta: {
      title: 'Mina nyhetsbrev | Chef Mina sidor',
      isPublic: true
    },
    component: NewslettersRoute
  },
  {
    path: '/fakturor',
    name: 'fakturor',
    meta: {
      title: 'Fakturor | Chef Mina sidor'
    },
    component: InvoicesRoute
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    meta: {
      title: 'Kontakt | Chef Mina sidor',
      isPublic: true
    },
    component: ContactRoute
  },
  {
    path: '/checkout/redirector',
    name: 'checkout-redirector',
    meta: {
      title: 'Beställning | Chef Mina sidor',
      isCallback: true
    },
    component: CheckoutRedirector
  },
  {
    path: '/checkout/bestallning',
    name: 'checkout-success',
    meta: {
      title: 'Tack för din beställning | Chef Mina sidor',
      isCallback: true
    },
    component: CheckoutSuccessRoute
  },
  {
    path: '/checkout/:offer_id',
    name: 'checkout',
    meta: {
      title: 'Beställning | Chef Mina sidor'
    },
    component: CheckoutRoute
  },
  {
    path: '/purchase/:publickey/:productnumber?',
    name: 'purchase',
    meta: {
      title: 'Beställning | Chef Mina sidor',
      isPublic: true
    },
    component: CheckoutRoute
  },
  {
    path: '/renewal/:publickey/:subscription_id?',
    name: 'renewal',
    meta: {
      title: 'Beställning | Chef Mina sidor',
      isPublic: true
    },
    component: CheckoutRoute
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: 'Sidan hittades inte | Chef Mina sidor',
      isPublic: true
    },
    component: FileNotFoundRoute
  },
  {
    path: '/signin-oidc',
    name: 'signin-oidc',
    meta: {
      title: 'Vänta... | Chef Mina sidor',
      isPublic: true,
      isOidcCallback: true
    },
    component: SsoSignInCallback
  },
  {
    path: '/signout-callback-oidc',
    name: 'signout-callback-oidc',
    meta: {
      title: 'Loggar ut... | Chef Mina sidor',
      isPublic: true,
      isCallback: true
    },
    component: SsoSignOutCallback
  },
  {
    path: '/paperton',
    name: 'paperton',
    meta: {
      title: 'Läs Chef digitalt... | Chef Mina sidor'
    },
    component: PapertonRoute
  }
]

export const callbackRouteNames = () => {
  return routes.filter(r => r.isOidcCallback || r.isCallback).map((r) => r.name)
}

Vue.use(Router)

const r = new Router({
  mode: 'history',
  routes: routes
})
r.beforeEach((to, from, next) => {
  if (to.path !== '/' && !to.meta.isOidcCallback) { // Mobile menu is closed when navigating between routes
    store.dispatch('closeMenuOnSmallScreens')
  }
  if (to.meta.title) { // Set page title if navigating to a route
    document.title = to.meta.title
    next()
  } else { // Go to 404 if no route is found
    r.push('/404')
  }
})
r.beforeEach(vuexOidcCreateRouterMiddleware(store))

export const router = r
