import cloneDeep from 'lodash.clonedeep'

export default {
  data: function () {
    return {
      formData: null
    }
  },
  methods: {
    initForm: function (initialState) {
      if (!this.formData && initialState) {
        this.formData = cloneDeep(initialState)
      }
    },
    touchInput: function (key, index = null, attr = null) {
      if (index !== null && attr !== null) {
        this.$v.formData[key].$each[index][attr].$touch()
      } else {
        this.$v.formData[key].$touch()
      }
    },
    formIsValid: function () {
      if (this.$v) {
        if (this.$v.formData.$invalid) {
          this.$v.formData.$touch()
        } else {
          this.$v.formData.$reset()
        }
        return !this.$v.formData.$invalid
      }
      return true
    }
  }
}
