<template>
  <div class="Tool-tip" ref="el">
    <span
      class="Tool-tip__trigger"
      ref="trigger"
      @click="toggle()"
      @touchend.stop
    >
      ?
    </span>
    <transition name="fade">
      <div
        v-if="expanded"
        class="Tool-tip__content"
        :style="tooltipContentPosition"
        @click.stop
      >
        <div class="Tool-tip__content__inner">
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const contentWidth = 200
const pagePadding = 24

export default {
  name: 'ToolTip',
  data () {
    return {
      expanded: false
    }
  },
  computed: {
    tooltipContentPosition: function () {
      const style = {}
      const elRect = this.$refs.el.getBoundingClientRect()
      const closeToLeftEdge = elRect.x < contentWidth / 2 + pagePadding
      const closeToRightEdge = elRect.x + contentWidth / 2 + pagePadding > window.innerWidth
      if (closeToLeftEdge) {
        style.left = (pagePadding - elRect.x) + 'px'
        style.marginLeft = '0'
      } else if (closeToRightEdge) {
        style.left = 'auto'
        style.right = (elRect.x + pagePadding + elRect.width - window.innerWidth) + 'px'
        style.marginLeft = '0'
      }
      return style
    }
  },
  mounted: function () {
    document.getElementsByTagName('body')[0].addEventListener('click', this.collapse)
    document.getElementsByTagName('body')[0].addEventListener('touchend', this.collapse)
  },
  destroyed: function () {
    document.getElementsByTagName('body')[0].removeEventListener('click', this.collapse)
    document.getElementsByTagName('body')[0].removeEventListener('touchend', this.collapse)
  },
  methods: {
    toggle: function () {
      this.expanded = !this.expanded
    },
    collapse: function (e) {
      if (e.target !== this.$refs.trigger) {
        this.expanded = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .Tool-tip {
    display: inline-block;
    position: relative;
    top: 1px;

    &__trigger {
      background: url('@/assets/images/tooltip-trigger.svg') no-repeat;
      cursor: pointer;
      display: inline-block;
      height: 12px;
      overflow: hidden;
      text-indent: -999em;
      width: 12px;
    }

    &__content {
      background: $bg-color;
      border-radius: 2px;
      color: white;
      font-size: 11px;
      font-weight: normal;
      line-height: 1.3;
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -100px;
      margin-bottom: 8px;
      transition: opacity .5s;
      width: 200px;
      z-index: $z-layer;

      &__inner {
        padding: $spacing/2 $spacing*0.5;
        max-height: 220px;
        overflow: hidden;
        -webkit-overflow-scrolling: touch;
      }

      &:after {
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $bg-color;
        left: 50%;
        height: 0;
        margin-left: -6px;
        position: absolute;
        top: 100%;
        width: 0;
        z-index: 1000000;
      }
    }
  }
</style>
