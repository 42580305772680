import { gaPageView, gaEvent } from './ga-tracking'
import { trackCheckoutStep1, trackCheckoutStep2, trackCheckoutStep2Update, trackCheckoutStep3, trackCheckoutStep3Update, trackCheckoutStep4, trackCheckoutStep4Update, trackPurchase } from './ecommerce-tracking'
import store from '@/store'

const checkoutElId = 'qvalia-checkout-widget'
const checkoutEventNamePrefix = 'qvaliacheckout:'
const checkoutTrackingCategory = 'Qvalia Checkout'

let payer = 'company'

function getCheckoutEl () {
  return document.getElementById(checkoutElId)
}

export const setupQvaliaCheckoutEventListeners = (createOrderCallback) => {
  const checkoutEl = getCheckoutEl()
  if (!checkoutEl) {
    return false
  }
  setupQvaliaCheckoutTracking()
  setupQvaliaCheckoutCallback(createOrderCallback)
  return true
}

function setupQvaliaCheckoutTracking () {
  const checkoutEl = getCheckoutEl()
  if (!checkoutEl) {
    return false
  }
  checkoutEl.addEventListener(checkoutEventNamePrefix + 'init', function (e) {
    gaEvent(checkoutTrackingCategory, 'Öppnas', e.detail.productName)
    if (e.detail.products.length) {
      let product = e.detail.defaultProductNumber ? e.detail.products.find(p => p.product_number === e.detail.defaultProductNumber) : null
      if (!product) {
        product = e.detail.products[0]
      }
      ecommerceTrackCheckout(productToEcommerceProduct(product))
    }
  })
  checkoutEl.addEventListener(checkoutEventNamePrefix + 'product_updated', function (e) {
    ecommerceTrackCheckout(productToEcommerceProduct(e.detail.product))
  })
  checkoutEl.addEventListener(checkoutEventNamePrefix + 'payer_updated', function (e) {
    trackCheckoutStep2Update(e.detail.payer === 'person')
    trackCheckoutStep3Update(e.detail.payer === 'person')
    payer = e.detail.payer
  })
  checkoutEl.addEventListener(checkoutEventNamePrefix + 'payment_method_updated', function (e) {
    trackCheckoutStep4Update(e.detail.paymentMethod)
  })
  checkoutEl.addEventListener(checkoutEventNamePrefix + 'section_finished', function (e) {
    const sectionNames = ['företagsuppgifter', 'faktureringsuppgifter', 'leveransadress', 'betalning']
    const sectionIndex = parseInt(e.detail.section, 10)
    const sectionName = sectionNames.length > sectionIndex ? sectionNames[sectionIndex] : null
    gaEvent(checkoutTrackingCategory, 'Steg ' + sectionName + ' slutfördes', e.detail.productName)
    if (sectionName === 'faktureringsuppgifter') {
      trackCheckoutStep4(payer === 'person' ? 'creditCard' : 'invoice')
    }
  })
  return true
}

function ecommerceTrackCheckout (product) {
  trackCheckoutStep1([product], store.getters.profileIsNew)
  trackCheckoutStep2()
  trackCheckoutStep3()
}

export function ecommerceTrackPurchase (orderNumber, products, productName) {
  gaEvent(checkoutTrackingCategory, 'Slutförde köp', productName)
  trackPurchase(orderNumber, products.map(productToEcommerceProduct))
}

function setupQvaliaCheckoutCallback (callback) {
  getCheckoutEl().addEventListener(checkoutEventNamePrefix + 'createorder', function (e) {
    callback(e.detail)
    const offerId = e.detail.order.offers[0].offer_id
    const url = '/checkout/bestallning?product_number=' + offerId
    gaPageView(url)
    if (window.history) {
      window.history.replaceState({}, 'Tack för din beställning | Chef Självserviceportal', url)
    }
  })
}

function productToEcommerceProduct (product) {
  const name = product.name || ''
  const category = name.toLowerCase().includes('chefboken') ? 'chefboken' : 'prenumeration'
  return {
    id: product.offer_id,
    name,
    category,
    brand: 'Chef',
    price: parseFloat(product.price),
    tax: Math.round(parseFloat(product.price) * parseFloat(product.vat)) / 100,
    quantity: 1
  }
}
