<template>
  <div>
    <PreLoader :delay="0" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { router } from '@/routes/router'
import PreLoader from '@/components/PreLoader'

export default {
  name: 'SsoSignInCallback',
  components: { PreLoader },
  methods: {
    ...mapActions([
      'oidcSignInCallback'
    ])
  },
  created () {
    this.oidcSignInCallback()
      .then((redirectPath) => {
        router.push(redirectPath)
      })
      .catch((err) => {
        console.error(err.message)
        if (err.message === 'No matching state found in storage') {
          router.push('/kontakt')
        }
      })
  }
}
</script>
