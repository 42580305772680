<template>
  <div :class="wrapperClasses">
    <div
      v-if="label"
      class="Form-component-wrapper__label"
    >
      {{ label }}
      <ToolTip v-if="tooltip">
        <p>
          {{ tooltip }}
        </p>
      </ToolTip>
    </div>

    <div
      v-for="option in options"
      :key="option.value"
      :class="inputClasses"
    >
      <input
        :id="id + '_' + option.value"
        :value="option.value"
        type="radio"
        class="Radiobutton__input"
        :checked="value === option.value"
        @change="updateChecked($event.target.value)"
      />
      <label
        :for="id + '_' + option.value"
        class="Radiobutton__text"
      >
        {{ option.text }}
      </label>
    </div>

    <div
      v-if="validation && validation.$error"
      class="Form-component-wrapper__error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  name: 'RadiobuttonsInput',
  mixins: [InputMixin],
  props: {
    value: {
      type: [Boolean, String]
    },
    options: {
      type: Array
    },
    type: {
      type: String,
      default: 'string'
    },
    layout: {
      type: String,
      default: 'vertical'
    }
  },
  data () {
    return {
      checkedValue: this.value
    }
  },
  computed: {
    inputClasses: function () {
      return {
        Radiobutton: true,
        'Radiobutton--vertical': this.layout === 'vertical',
        'Radiobutton--horizontal': this.layout === 'horizontal',
        'Radiobutton--has-error': this.error
      }
    }
  },
  methods: {
    updateChecked: function (checkedValue) {
      if (this.type === 'boolean') {
        this.checkedValue = checkedValue === 'true'
      } else if (this.type === 'integer') {
        this.checkedValue = parseInt(checkedValue, 10)
      } else {
        this.checkedValue = checkedValue
      }
      this.$emit('touch')
      this.$emit('input', this.checkedValue)
    }
  }
}
</script>
