import { NETWORK_STATUSES } from '@/services/http'
import * as api from '@/services/api'

const state = {
  profile: null,
  profileEditArea: null,
  networkStatus: NETWORK_STATUSES.WAITING
}

const actions = {
  getProfile ({ commit }) {
    commit('setProfileNetworkStatus', NETWORK_STATUSES.GETTING)
    commit('setProfile', null)
    api.getProfile()
      .then(profile => {
        commit('setProfileNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setProfile', profile)
      })
      .catch((error) => {
        console.error(error)
        commit('setProfileNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  getProfileAddress ({ state, commit }) {
    api.getProfileAddress()
      .then(profileAddress => {
        commit('setProfile', {
          ...state.profile,
          ...profileAddress
        })
      })
      .catch((error) => {
        console.error(error)
        commit('setProfileNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  updateProfile ({ dispatch, commit }, profile) {
    commit('setProfileNetworkStatus', NETWORK_STATUSES.SUBMITTING)
    api.updateProfile(profile)
      .then(profileResponse => {
        commit('setProfileNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setProfile', profileResponse)
        dispatch('showInfo', 'Ändringar sparade')
      })
      .catch((error) => {
        console.error(error)
        commit('setProfileNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  setProfileEditArea ({ commit }, areaToEdit) {
    commit('setProfileEditArea', areaToEdit)
  },
  unsetProfileEditArea ({ commit }) {
    commit('setProfileEditArea', null)
  }
}

const getters = {
  profileIsNew (state) {
    if (state.profile && state.profile.createdon) {
      return Date.now() - new Date(state.profile.createdon).getTime() < 60000
    }
    return false
  }
}

const mutations = {
  setProfile (state, profile) {
    state.profile = profile
    state.profileEditArea = null
  },
  setProfileEditArea (state, areaToEdit) {
    state.profileEditArea = areaToEdit
  },
  setProfileNetworkStatus (state, status) {
    state.networkStatus = status
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
