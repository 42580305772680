import Vue from 'vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import { router } from './routes/router'
import store from './store'
import ResetScroll from './directives/reset-scroll'
import ConfirmDialog from './directives/confirm-dialog'

require('smoothscroll-polyfill').polyfill()

if (process.env.NODE_ENV !== 'production') {
  Vue.config.productionTip = false
}
Vue.use(Vuelidate)
Vue.directive('reset-scroll', ResetScroll)
Vue.directive('confirm-dialog', ConfirmDialog)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
