<template>
  <div>
    <PreLoader v-if="isLoading" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { NETWORK_STATUSES } from '@/services/http'
import store from '@/store'
import { getQueryParam } from '@/services/uri'

import PreLoader from '@/components/PreLoader'

export default {
  name: 'CheckoutRedirector',
  components: { PreLoader },
  computed: {
    ...mapState({
      qvaliaNetworkStatus: state => state.qvaliaStore.networkStatus
    }),
    ...mapGetters([
      'oidcIsAuthenticated',
      'oidcAuthenticationIsChecked'
    ]),
    isLoading: function () {
      return this.qvaliaNetworkStatus === NETWORK_STATUSES.WAITING
    }
  },
  created () {
    const payload = {
      offer_id: getQueryParam('offer_id'),
      product_category: getQueryParam('product_category'),
      referrer: {
        url: getQueryParam('referrer_url'),
        source: getQueryParam('referrer_source'),
        medium: getQueryParam('referrer_medium'),
        campaign: getQueryParam('referrer_campaign'),
        content: getQueryParam('referrer_content')
      }
    }
    store.dispatch('redirectToCheckout', { payload })
  }
}
</script>
