<template>
  <div>
    <div v-if="newsletters">
      <ContentHeader>
        <h1>Mina nyhetsbrev</h1>
      </ContentHeader>
      <AreaNotifications area="newsletters" />
      <h2>
        Jag vill ha utskick gällande
      </h2>
      <NewslettersForm />
      <AppMenu :extraMenuAtBottom="true" />
    </div>
    <PreLoader v-else />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { getQueryParam } from '@/services/uri'
import AppMenu from '@/components/AppMenu'
import PreLoader from '@/components/PreLoader'
import ContentHeader from '@/components/ContentHeader'
import AreaNotifications from '@/components/AreaNotifications'
import RouteMixin from '../route-mixin'
import NewslettersForm from './components/NewslettersForm'

export default {
  name: 'NewslettersRoute',
  components: { AppMenu, PreLoader, ContentHeader, AreaNotifications, NewslettersForm },
  mixins: [RouteMixin],
  computed: {
    ...mapState({
      newsletters: state => state.newslettersStore.newsletters
    }),
    ...mapGetters([
      'oidcUser'
    ]),
    token: function () {
      return getQueryParam('token')
    }
  },
  methods: {
    ...mapActions([
      'getNewsletters',
      'getNewslettersByApsisToken'
    ])
  },
  created () {
    if (this.oidcUser) {
      this.requireCrmData('Newsletters')
    } else {
      if (this.token) {
        this.getNewslettersByApsisToken(this.token)
      } else {
        window.addEventListener('vuexoidc:wasauthenticated', this.getNewsletters)
      }
    }
  },
  destroyed () {
    window.removeEventListener('vuexoidc:wasauthenticated', this.getNewsletters)
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    @extend %title-S;
    margin-bottom: $spacing/2;
  }
</style>
