export default {
  bind (el, binding) {
    if (binding.value) {
      el.addEventListener('click', scrollToTop)
    }
  }
}

function scrollToTop () {
  window.scrollTo(0, 0)
}
