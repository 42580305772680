<template>
  <div>
    <PreLoader />
  </div>
</template>

<script>
import { getBackUrlQuery } from '@/services/uri'

import PreLoader from '@/components/PreLoader'

export default {
  name: 'SsoSignOutCallback',
  components: { PreLoader },
  created () {
    document.location = getBackUrlQuery({ auth_logout_wp: '1' })
  }
}
</script>
