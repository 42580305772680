import Vue from 'vue'
import Vuex from 'vuex'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'

import { getBackUrl } from '@/services/uri'
import profileStore from './modules/profile-store'
import qvaliaStore from './modules/qvalia-store'
import papertonStore from './modules/paperton-store'
import subscriptionsStore from './modules/subscriptions-store'
import offersStore from './modules/offers-store'
import newslettersStore from './modules/newsletters-store'
import termsStore from './modules/terms-store'
import notificationsStore from './modules/notifications-store'
import messageStore from './modules/message-store'
import menuStore from './modules/menu-store'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    oidcStore: vuexOidcCreateStoreModule({
      ...JSON.parse(process.env.VUE_APP_OIDC_CONFIG),
      // userStore: new WebStorageStateStore({ store: window.sessionStorage }),
      extraQueryParams: {
        client_back_url: getBackUrl()
      }
    }),
    profileStore,
    papertonStore,
    qvaliaStore,
    subscriptionsStore,
    offersStore,
    newslettersStore,
    termsStore,
    notificationsStore,
    messageStore,
    menuStore
  },
  strict: debug
})
