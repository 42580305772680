const state = {
  message: null,
  confirmDialogMessage: null
}

const getters = {
  getMessage: (state) => {
    return state.message
  },
  getConfirmDialogMessage: (state) => {
    return state.message
  }
}

let timeout

const actions = {
  showInfo ({ dispatch }, text) {
    dispatch('showMessage', { text: text })
  },
  showError ({ dispatch }, text) {
    dispatch('showMessage', { text: text, type: 'error' })
  },
  showMessage ({ commit }, message = {}) {
    clearTimeout(timeout)
    message = {
      text: '',
      type: 'info',
      ...message
    }
    commit('setMessage', message)
    timeout = setTimeout(() => {
      commit('clearMessage')
    }, 3000)
  },
  hideMessage ({ commit }) {
    commit('clearMessage')
  },
  showConfirmDialogMessage ({ commit }, confirmDialogMessage) {
    commit('setConfirmDialogMessage', confirmDialogMessage)
  },
  hideConfirmDialogMessage ({ commit }) {
    commit('clearConfirmDialogMessage')
  }
}

const mutations = {
  setMessage (state, message) {
    state.message = message
  },
  clearMessage (state) {
    state.message = null
  },
  setConfirmDialogMessage (state, confirmDialogMessage) {
    state.confirmDialogMessage = confirmDialogMessage
  },
  clearConfirmDialogMessage (state) {
    state.confirmDialogMessage = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
