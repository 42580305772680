import ToolTip from '@/components/ToolTip'

export default {
  components: { ToolTip },
  props: {
    label: {
      type: String
    },
    inGroup: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    requiredErrorMessage: {
      type: String
    },
    tooltip: {
      type: String
    }
  },
  data () {
    return {
      id: null
    }
  },
  computed: {
    wrapperClasses: function () {
      return {
        'Form-component-wrapper': true,
        'Form-component-wrapper--in-group': this.inGroup,
        'Form-component-wrapper--has-error': this.validation && this.validation.$error,
        'Form-component-wrapper--required': this.validation && this.validation.$params.required,
        'Form-component-wrapper--has-label': this.label
      }
    },
    errorMessage: function () {
      const paramWithError = Object.keys(this.validation.$params).find(p => !this.validation[p])
      return this.getErrorMessage(paramWithError, this.validation.$params[paramWithError])
    }
  },
  mounted () {
    this.id = this._uid
  },
  methods: {
    getErrorMessage: function (param, paramData) {
      if (param === 'email') {
        return 'Ogiltig e-postadress'
      } else if (param === 'required') {
        return this.requiredErrorMessage ? this.requiredErrorMessage : 'Obligatorisk uppgift'
      } else if (param === 'numeric') {
        return 'Inte ett giltigt nummer'
      } else if (param === 'integer') {
        return 'Måste vara ett heltal'
      } else if (param === 'decimal') {
        return 'Inte ett giltigt belopp'
      } else if (param === 'minLength') {
        return 'Måste vara minst ' + paramData.min + ' tecken'
      } else if (param === 'maxLength') {
        return 'Måste vara max ' + paramData.max + ' tecken'
      } else if (param === 'isValidOrgNo') {
        return 'Ogiltigt organisationsnummer. Kontrollera att det stämmer'
      } else if (param === 'zipCode') {
        return 'Ogiltigt postnummer'
      }
      return null
    }
  }
}
