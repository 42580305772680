import { NETWORK_STATUSES } from '@/services/http'
import * as api from '@/services/api'

const state = {
  notifications: null,
  notificationsNetworkStatus: NETWORK_STATUSES.WAITING
}

const getters = {
  areaNotifications: (state) => {
    return (area) => {
      if (state.notifications) {
        return state.notifications.filter(notifications => notifications.area === area)
      }
      return []
    }
  }
}

const actions = {
  getNotifications ({ commit }) {
    commit('setNotificationsNetworkStatus', NETWORK_STATUSES.GETTING)
    api.getNotifications()
      .then(notifications => {
        commit('setNotificationsNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setNotifications', notifications)
      })
      .catch((error) => {
        console.error(error)
        commit('setNotificationsNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  }
}

const mutations = {
  setNotifications (state, notifications) {
    state.notifications = notifications
  },
  setNotificationsNetworkStatus (state, status) {
    state.notificationsNetworkStatus = status
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
