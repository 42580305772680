import store from '@/store'

import * as http from './http'
import { onerror } from './api-errorhandler'
import * as apiMock from './api-mock'

const useMockForAll = process.env.VUE_APP_API_MOCK.toString() === 'true'

const mockedEndpoints = {
  contacts: false,
  profile: false,
  subscriptions: true,
  offers: true,
  newsletters: false,
  terms: true
}

function useMockForEndpoint (url) {
  return useMockForAll || mockedEndpoints[url.replace('/', '')]
}

function apiUrl (url) {
  if (useMockForEndpoint(url)) {
    return apiMock.getMockUrl(url)
  }
  return process.env.VUE_APP_API_BASE_URL + url
}

export function getContacts () {
  return new Promise((resolve, reject) => {
    http.get(
      {
        url: apiUrl('/contacts'),
        token: store.state.oidcStore.access_token,
        onerror: onerror
      }
    )
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getWidget (subscriptionId) {
  return new Promise((resolve, reject) => {
    http.get(
      {
        url: apiUrl(`/qvalia/widget/${subscriptionId}`),
        token: store.state.oidcStore.access_token,
        onerror: onerror
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getCheckout (offerId) {
  return new Promise((resolve, reject) => {
    http.get(
      {
        url: apiUrl(`/qvalia/checkout/${offerId}`),
        token: store.state.oidcStore.access_token,
        onerror: onerror
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function createOrder (order) {
  return new Promise((resolve, reject) => {
    http.post(
      {
        url: apiUrl('/qvalia/order'),
        token: store.state.oidcStore.access_token,
        onerror: onerror,
        json: order
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getProfile () {
  return new Promise((resolve, reject) => {
    http.get(
      {
        url: apiUrl('/profile'),
        token: store.state.oidcStore.access_token,
        onerror: onerror
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getProfileAddress () {
  return new Promise((resolve, reject) => {
    http.get(
      {
        url: apiUrl('/profile/address'),
        token: store.state.oidcStore.access_token,
        onerror: onerror
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function updateProfile (profile) {
  if (useMockForEndpoint('/profile')) {
    return apiMock.mockUpdateCall(profile)
  }
  return new Promise((resolve, reject) => {
    http.post(
      {
        url: apiUrl('/profile'),
        token: store.state.oidcStore.access_token,
        onerror: onerror,
        json: profile
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getOffers () {
  return new Promise((resolve, reject) => {
    http.get(
      {
        url: apiUrl('/offer'),
        token: store.state.oidcStore.access_token,
        onerror: onerror
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getSubscriptions () {
  return new Promise((resolve, reject) => {
    http.get(
      {
        url: apiUrl('/subscription'),
        token: store.state.oidcStore.access_token,
        onerror: onerror
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function updateSubscription (subscription) {
  if (useMockForEndpoint('/subscription')) {
    return apiMock.mockUpdateCall(subscription)
  }
  return new Promise((resolve, reject) => {
    http.post(
      {
        url: apiUrl('/subscription/' + subscription.id),
        token: store.state.oidcStore.access_token,
        onerror: onerror,
        json: subscription
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function createSubscription (subscription) {
  return new Promise((resolve) => {
    setTimeout(() => {
      subscription.id = Math.floor(Math.random() * 1000)
      resolve(subscription)
    }, 1000)
  })
}

export function getNewsletters () {
  return new Promise((resolve, reject) => {
    http.get(
      {
        url: apiUrl('/newsletter'),
        token: store.state.oidcStore.access_token,
        onerror: onerror
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function updateNewsletters (newsletters) {
  if (useMockForEndpoint('/newsletter')) {
    return apiMock.mockUpdateCall(newsletters)
  }
  return new Promise((resolve, reject) => {
    http.post(
      {
        url: apiUrl('/newsletter'),
        token: store.state.oidcStore.access_token,
        onerror: onerror,
        json: newsletters
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getNewslettersByApsisToken (apsisToken) {
  return new Promise((resolve, reject) => {
    http.get(
      {
        url: apiUrl('/newsletterbytoken/' + apsisToken),
        onerror: onerror
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function updateNewslettersWithApsisToken (newsletters, apsisToken) {
  if (useMockForEndpoint('/newsletter/')) {
    return apiMock.mockUpdateCall(newsletters)
  }
  return new Promise((resolve, reject) => {
    http.post(
      {
        url: apiUrl('/newsletterbytoken/' + apsisToken),
        onerror: onerror,
        json: newsletters
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getTerms () {
  return new Promise((resolve, reject) => {
    http.get(
      {
        url: apiUrl('/terms'),
        token: store.state.oidcStore.access_token,
        onerror: onerror
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function updateTerm (term) {
  if (useMockForEndpoint('/terms')) {
    return apiMock.mockUpdateCall(term)
  }
  return new Promise((resolve, reject) => {
    http.post(
      {
        url: apiUrl('/terms/' + term.id),
        token: store.state.oidcStore.access_token,
        onerror: onerror,
        data: term
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getNotifications () {
  return new Promise((resolve, reject) => {
    http.get(
      {
        url: apiUrl('/notifications'),
        token: store.state.oidcStore.access_token,
        onerror: onerror
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function updateNotification (notification) {
  if (useMockForEndpoint('/notifications')) {
    return apiMock.mockUpdateCall(notification)
  }
  return new Promise((resolve, reject) => {
    http.post(
      {
        url: apiUrl('/notifications/' + notification.id),
        token: store.state.oidcStore.access_token,
        onerror: onerror,
        data: notification
      }
    )
      .then(response => {
        resolve(JSON.parse(response))
      })
      .catch(error => {
        reject(error)
      })
  })
}

export function getPapertonLoginUrl () {
  return new Promise((resolve, reject) => {
    http.get({
      url: apiUrl('/paperton/login_url'),
      token: store.state.oidcStore.access_token,
      onerror: onerror
    })
      .then(response => {
        resolve(response ? JSON.parse(response) : null)
      })
      .catch(error => {
        reject(error)
      })
  })
}
