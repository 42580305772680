<template>
  <div>
    <form novalidate="true" @submit.prevent="submitForm" v-if="formData">
      <TextInput
        v-model="formData.address_line1"
        type="text"
        label="Adress"
        placeholder="Ange postadress"
        :validation="$v.formData.address_line1"
        @touch="touchInput('address_line1')"
      />
      <TextInput
        v-model="formData.address_line2"
        type="text"
        label="Adress rad 2"
        placeholder="Ange postadress"
        :validation="$v.formData.address_line2"
        @touch="touchInput('address_line2')"
      />
      <div class="Grid--wider">
        <div class="Grid__col Grid__col--4">
          <TextInput
            v-model="formData.zip_code"
            type="text"
            label="Postnummer"
            placeholder="Ange postnummer"
            :validation="$v.formData.zip_code"
            @touch="touchInput('zip_code')"
            />
        </div>
        <div class="Grid__col Grid__col--8">
          <TextInput
            v-model="formData.city"
            type="text"
            label="Ort"
            placeholder="Ange ort"
            :validation="$v.formData.city"
            @touch="touchInput('city')"
          />
        </div>
      </div>

      <SubmitButton
        :submitting="submitting"
      >
        Spara
      </SubmitButton>

      <a
        href
        class="Cancel-link"
        @click.prevent="unsetSubscriptionEditArea()"
      >
        Avbryt
      </a>

      <div
        v-if="$v.$error"
        class="Form-error"
      >
        Formuläret innehåller fel.
      </div>
    </form>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { NETWORK_STATUSES } from '@/services/http'
import { deliveryAddressValidations } from '@/models/subscription'
import FormMixin from '@/components/form/FormMixin'
import TextInput from '@/components/form/TextInput'
import SubmitButton from '@/components/form/SubmitButton'

export default {
  name: 'SubscriptionDeliveryAddressForm',
  components: { TextInput, SubmitButton },
  mixins: [FormMixin],
  validations: {
    formData: deliveryAddressValidations
  },
  computed: {
    ...mapState({
      subscriptionsNetworkStatus: state => state.subscriptionsStore.networkStatus
    }),
    ...mapGetters([
      'getSubscription'
    ]),
    subscription: function () {
      return this.getSubscription(this.$route.params.id)
    },
    submitting: function () {
      return this.subscriptionsNetworkStatus === NETWORK_STATUSES.SUBMITTING
    }
  },
  mounted () {
    this.initForm(this.subscription.delivery_address)
  },
  methods: {
    ...mapActions([
      'updateSubscription',
      'unsetSubscriptionEditArea'
    ]),
    submitForm: function () {
      if (this.formIsValid()) {
        this.updateSubscription({
          ...this.subscription,
          delivery_address: this.formData
        })
      }
    }
  }
}
</script>
