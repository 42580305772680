if (window.location.pathname === '/' && window.sessionStorage) {
  window.sessionStorage.setItem('chef_spp_menu_open_on_small_screens', 'true')
}

const state = {
  menuOpenOnSmallScreens: window.sessionStorage && window.sessionStorage.getItem('chef_spp_menu_open_on_small_screens'),
  menuHasBeenClosed: false,
  backUrl: process.env.VUE_APP_BACK_URL
}

const actions = {
  openMenuOnSmallScreens ({ commit }) {
    commit('setIfMenuOpenOnSmallScreens', true)
  },
  closeMenuOnSmallScreens ({ commit }) {
    commit('setIfMenuOpenOnSmallScreens', false)
    if (window.sessionStorage) {
      window.sessionStorage.removeItem('chef_spp_menu_open_on_small_screens')
    }
  },
  setBackUrl ({ commit }, backUrl) {
    commit('setBackUrl', backUrl)
  }
}

const mutations = {
  setIfMenuOpenOnSmallScreens (state, menuOpenOnSmallScreens) {
    state.menuOpenOnSmallScreens = menuOpenOnSmallScreens
    if (!menuOpenOnSmallScreens) {
      state.menuHasBeenClosed = true
    }
  },
  setBackUrl (state, backUrl) {
    state.backUrl = backUrl
  }
}

export default {
  state,
  actions,
  mutations
}
