import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { formatDistance, addDays, format } from 'date-fns'
import sv from 'date-fns/locale/sv'

export const deliveryAddressValidations = {
  address: {
    required
  },
  zip_code: {
    required,
    minLength: minLength(5),
    maxLength: maxLength(6)
  },
  city: {
    required
  }
}

export const licenseValidations = {
  email: {
    required,
    email
  }
}

export const expiresSoon = (offer) => {
  // Soon is within 100 days
  if (offer.active) {
    const expiresDate = offer.expires ? new Date(offer.expires) : false
    const nowDate = new Date()
    if (expiresDate && expiresDate > nowDate && expiresDate < addDays(nowDate, 100)) {
      return true
    }
  }
  return false
}

export const status = (offer) => {
  if (offer.active) {
    if (offer.expires) {
      return formatDistance(new Date(offer.expires), new Date(), { locale: sv }) + ' kvar'
    }
    return 'Aktiv'
  }
  return 'Inaktiv'
}

export const statusClasses = (offer) => {
  return {
    Status: true,
    'Status--inactive': !offer.active,
    'Status--expires': expiresSoon(offer)
  }
}

export const duration = (offer) => {
  if (offer.active) {
    return format(new Date(offer.created), 'yyyy-MM-dd') + ' – ' + format(new Date(offer.expires), 'yyyy-MM-dd')
  }
  return '-'
}

export const invoiceDueDate = (invoice) => {
  return format(addDays(new Date(invoice.created), invoice.payment_terms), 'yyyy-MM-dd')
}

export const invoiceDate = (invoice) => {
  return format(new Date(invoice.created), 'yyyy-MM-dd')
}
