<template>
  <div :class="wrapperClasses">
    <div
      v-if="label"
      class="Form-component-wrapper__label"
    >
      {{ label }}
      <ToolTip v-if="tooltip">
        <p>
          {{ tooltip }}
        </p>
      </ToolTip>
    </div>

    <div :class="inputClasses">
      <input
        :id="id"
        type="checkbox"
        class="Checkbox__input"
        :checked="isChecked"
        :disabled="disabled"
        @change="updateChecked($event.target.checked)"
      />
      <label
        :for="id"
        class="Checkbox__text"
      >
        {{ text }}
      </label>
    </div>

    <div
      v-if="validation && validation.$error"
      class="Form-component-wrapper__error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  name: 'CheckboxInput',
  mixins: [InputMixin],
  props: {
    value: {
      type: Boolean
    },
    text: {
      type: String
    }
  },
  data () {
    return {
      isChecked: this.value
    }
  },
  computed: {
    inputClasses: function () {
      return {
        Checkbox: true,
        'Checkbox--has-error': this.validation && this.validation.$error
      }
    }
  },
  methods: {
    updateChecked: function (isChecked) {
      this.isChecked = isChecked
      this.$emit('touch')
      this.$emit('input', this.isChecked)
    }
  }
}
</script>
