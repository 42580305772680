import { NETWORK_STATUSES } from '@/services/http'
import * as api from '@/services/api'

const state = {
  newsletters: null,
  networkStatus: NETWORK_STATUSES.WAITING
}

const actions = {
  getNewsletters ({ commit }) {
    commit('setNewslettersNetworkStatus', NETWORK_STATUSES.GETTING)
    commit('setNewsletters', null)
    api.getNewsletters()
      .then(newsletters => {
        commit('setNewslettersNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setNewsletters', newsletters)
      })
      .catch((error) => {
        console.error(error)
        commit('setNewslettersNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  updateNewsletters ({ dispatch, commit }, newsletters) {
    commit('setNewslettersNetworkStatus', NETWORK_STATUSES.SUBMITTING)
    api.updateNewsletters(newsletters)
      .then(newslettersResponse => {
        commit('setNewslettersNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setNewsletters', newslettersResponse)
        dispatch('showInfo', 'Inställningar för nyhetsbrev sparade')
      })
      .catch((error) => {
        console.error(error)
        commit('setNewslettersNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  getNewslettersByApsisToken ({ commit }, token) {
    commit('setNewslettersNetworkStatus', NETWORK_STATUSES.GETTING)
    api.getNewslettersByApsisToken(token)
      .then(newsletters => {
        commit('setNewslettersNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setNewsletters', newsletters)
      })
      .catch((error) => {
        console.error(error)
        commit('setNewslettersNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  },
  updateNewslettersByToken ({ dispatch, commit }, data) {
    commit('setNewslettersNetworkStatus', NETWORK_STATUSES.SUBMITTING)
    api.updateNewslettersWithApsisToken(data.newsletters, data.token)
      .then(newslettersResponse => {
        commit('setNewslettersNetworkStatus', NETWORK_STATUSES.SUCCESS)
        commit('setNewsletters', newslettersResponse)
        dispatch('showInfo', 'Inställningar för nyhetsbrev sparade')
      })
      .catch((error) => {
        console.error(error)
        commit('setNewslettersNetworkStatus', NETWORK_STATUSES.ERROR)
      })
  }
}

const mutations = {
  setNewsletters (state, newsletters) {
    state.newsletters = newsletters
  },
  setNewslettersNetworkStatus (state, status) {
    state.networkStatus = status
  }
}

export default {
  state,
  actions,
  mutations
}
