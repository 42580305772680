<template>
  <div>
    <ContentHeader>
      <h1>Checkout</h1>
    </ContentHeader>
    <p v-if="disableCheckout">Just nu har vi tekniska problem, du kan istället mejla din beställning till <a href="mailto:kundtjanst@chef.se">kundtjanst@chef.se</a>.</p>
    <div v-else>
      <div id="qvalia-checkout-widget" class="qvaliacheckout">
        <script v-if="checkout" type="text/props">
          {{ checkout }}
        </script>
        <script type="text/props">
          {{ checkoutFormDefaults }}
        </script>
      </div>
      <PreLoader v-if="!checkout" :delay="0" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { NETWORK_STATUSES } from '@/services/http'
import { loadScript } from '@/services/dom-helpers'
import store from '@/store'
import { setupQvaliaCheckoutEventListeners } from '@/services/qvalia-checkout'
import { getBackUrlQuery } from '@/services/uri'

import RouteMixin from '../route-mixin'
import ContentHeader from '@/components/ContentHeader'
import PreLoader from '@/components/PreLoader'

export default {
  name: 'CheckoutRoute',
  components: { ContentHeader, PreLoader },
  mixins: [RouteMixin],
  created () {
    store.dispatch('getCheckout', { offer_id: this.$route.params.offer_id })
    if (this.oidcIsAuthenticated) {
      store.dispatch('getProfileAddress')
    }
  },
  destroyed () {
    store.dispatch('clearCheckout')
  },
  computed: {
    ...mapState({
      profile: state => state.profileStore.profile,
      defaultEmail: state => state.qvaliaStore.defaultEmail,
      referrer: state => state.qvaliaStore.referrer || { url: '/' },
      qvaliaNetworkStatus: state => state.qvaliaStore.networkStatus
    }),
    ...mapGetters([
      'getCheckout',
      'oidcIsAuthenticated',
      'oidcUser'
    ]),
    ...mapActions([
      'createOrder'
    ]),
    checkout: function () {
      const _checkout = this.getCheckout(this.$route.params.offer_id)
      if (_checkout) {
        // _checkout.offer_id = this.$route.params.offer_id
        _checkout.afterpurchaselinkurl = getBackUrlQuery({ auth_login: '1' })
        _checkout.chefapibaseurl = process.env.VUE_APP_API_BASE_URL
      }
      return _checkout
    },
    checkoutFormDefaults: function () {
      const formDefaults = this.profile
        ? {
            defaultpaymentmethod: this.profile.payment_default,
            defaultorgno: this.profile.account.orgno,
            defaultaddress: this.profile.account.address_line1,
            defaultaddresslinetwo: this.profile.account.address_line2,
            defaultzipcode: this.profile.account.postalcode,
            defaultcity: this.profile.account.city
          }
        : {}
      if (this.defaultEmail) {
        formDefaults.email = this.defaultEmail
      } else if (this.profile && this.profile.email) {
        formDefaults.email = this.profile.email
      } else if (this.oidcUser && this.oidcUser.email) {
        formDefaults.email = this.oidcUser.email
      }
      return formDefaults
    },
    isLoading: function () {
      return this.qvaliaNetworkStatus === NETWORK_STATUSES.GETTING
    },
    disableCheckout: function () {
      return process.env.VUE_APP_DISABLE_CHECKOUT.toString() === 'true' && (document.location.href.indexOf('checkout=allow') === -1)
    }
  },
  watch: {
    checkout: function (newCheckout, oldCheckout) {
      // Load checkout script if the checkout is new
      if (newCheckout && !oldCheckout) {
        new Promise((resolve) => {
          // If Stripe is not already loaded: Load it before the widget
          if (!window.Stripe) {
            const stripejs = loadScript(process.env.VUE_APP_STRIPE_URL)
            stripejs.onload = () => {
              resolve()
            }
          } else {
            resolve()
          }
        }).then(() => {
          const widgetjs = loadScript(process.env.VUE_APP_QVALIA_WIDGET_URL)
          widgetjs.onload = () => {
            const createOrderCallback = (detail) => {
              const { order, signature } = { ...detail }
              store.dispatch('createOrder', { order, signature })
            }
            const trackingIsSetup = setupQvaliaCheckoutEventListeners(createOrderCallback)
            if (!trackingIsSetup) {
              setTimeout(() => {
                setupQvaliaCheckoutEventListeners(createOrderCallback)
              }, 100)
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    margin: $spacing*2 0 $spacing/2 0;
  }

  h3 {
    @extend %form-label;
    margin: $spacing 0 $spacing/4 0;
  }

  a {
    text-decoration: underline;

    &[href^="tel:"] {
      text-decoration: none;
    }
  }
</style>
