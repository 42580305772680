<template>
  <div :class="areaClasses">
    <div
      class="Toggle-area__trigger"
      @click="toggle"
    >
      <div class="Toggle-area__trigger__inner">
        <slot name="trigger"></slot>
      </div>
    </div>
    <div
      class="Toggle-area__content"
      :style="contentStyles"
    >
      <div class="Toggle-area__content__inner" ref="innerContent">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggleArea',
  props: {
    collapseTrigger: {
      type: Boolean,
      default: false
    },
    accordion: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: false,
      transitioning: false,
      innerCssHeight: 0
    }
  },
  computed: {
    areaClasses: function () {
      return {
        'Toggle-area': true,
        'Toggle-area--expanded': this.expanded,
        'Toggle-area--collapse-trigger': this.collapseTrigger,
        'Toggle-area--accordion': this.accordion
      }
    },
    contentStyles: function () {
      return {
        height: this.expanded ? this.innerCssHeight : '0'
      }
    }
  },
  mounted: function () {
    this.setInnerHeight()
    window.addEventListener('resize', this.setInnerHeight)
  },
  destroyed: function () {
    window.removeEventListener('resize', this.setInnerHeight)
  },
  methods: {
    toggle: function () {
      if (!this.transitioning) {
        this.expanded = !this.expanded
        this.transitioning = true
        setTimeout(() => {
          this.transitioning = false
        }, 200)
      }
    },
    setInnerHeight: function () {
      this.innerCssHeight = this.$refs.innerContent ? (this.$refs.innerContent.clientHeight + 12) + 'px' : '0'
    }
  }
}
</script>

<style lang="scss" scoped>
  .Toggle-area {

    &--accordion {
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;

      .Toggle-area--accordion + & {
        border-top: 0;
      }
    }

    &__trigger {
      cursor: pointer;

      .Toggle-area--accordion & {
        padding: $spacing/2 0;

        @media screen and (min-width: $site_wrapper_width+0px) {
          padding-top: $spacing*0.75;
          padding-bottom: $spacing*0.75;
        }
      }

      &__inner {
        position: relative;

        .Toggle-area--collapse-trigger & {
          display: inline-block;
        }

        &:after {
          content: '';
          background: url('@/assets/images/chevron-black.svg');
          position: absolute;
          margin-top: -5px;
          top: 50%;
          right: 10px;
          height: 9px;
          width: 14px;

          .Toggle-area--expanded & {
            transform: rotate(180deg)
          }

          .Toggle-area--collapse-trigger & {
            right: -20px;
          }
        }
      }
    }

    &__content {
      overflow: hidden;
      height: 0;
      transition: height 0.2s ease-out;

      &__inner {
        .Toggle-area--collapse-trigger & {
          padding-top: $spacing/2;
        }
        .Toggle-area--accordion & {
          padding-bottom: $spacing/2;

          @media screen and (min-width: $site_wrapper_width+0px) {
            padding-bottom: $spacing*0.75;
          }
        }
      }
    }
  }
</style>
