<template>
  <div>
    <div v-if="subscriptions">
      <ContentHeader>
        <h1>Mina tjänster</h1>
      </ContentHeader>
      <AreaNotifications area="subscriptions" />
      <p>
        Här ser du de abonnemang och tjänster du har hos oss. För att säga upp ditt abonnemang kontaktar du <a href="mailto:kundtjanst@chef.se">kundtjänst</a> genom kundtjanst@chef.se.
      </p>
      <h2>
        Aktiva
      </h2>
      <div
        v-if="activeSubscriptions.length"
        class="Subscription-items"
      >
        <SubscriptionsSubscriptionItem
          v-for="subscription in activeSubscriptions"
          :key="subscription.id"
          :subscription="subscription"
        />
      </div>
      <div v-else>Du har inga aktiva tjänster</div>
      <div v-if="inactiveSubscriptions.length">
        <h2>
          Avslutade
        </h2>
        <div class="Subscription-items">
          <SubscriptionsSubscriptionItem
            v-for="subscription in inactiveSubscriptions"
            :key="subscription.id"
            :subscription="subscription"
          />
        </div>
      </div>
      <AppMenu :extraMenuAtBottom="true" />
    </div>
    <PreLoader v-else />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import AppMenu from '@/components/AppMenu'
import PreLoader from '@/components/PreLoader'
import ContentHeader from '@/components/ContentHeader'
import AreaNotifications from '@/components/AreaNotifications'
import RouteMixin from '../route-mixin'
import SubscriptionsSubscriptionItem from './components/SubscriptionsSubscriptionItem'

export default {
  name: 'SubscriptionsRoute',
  components: { AppMenu, PreLoader, ContentHeader, AreaNotifications, SubscriptionsSubscriptionItem },
  mixins: [RouteMixin],
  computed: {
    ...mapState({
      subscriptions: state => state.subscriptionsStore.subscriptions
    }),
    ...mapGetters([
      'activeSubscriptions',
      'inactiveSubscriptions'
    ])
  },
  created () {
    this.requireCrmData('Subscriptions')
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    @extend %title-S;
    margin-top: $spacing*2;
  }
  a {
    white-space: nowrap;
  }
</style>
