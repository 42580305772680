/**
* Kontrollerar om ett svenskt postnummer är giltigt i enlighet med
* Svenska Postnummersystemet och SS 613401:2011.
*
* För att retunera postnumret i ett visst format, kan en andra valfri
* parameter anges. De format som stöds är:
*
*     1. DEFAULT    = Numeriskt format (standard), NNNNN
*     2. NAT_FORMAT = Nationellt format, NNN NN
*     3. INT_FORMAT = Internationellt format, SE-NNN NN
*
* Observera att funktionen endast kontrollerar om angivet postnummer
* ligger inom ett giltigt spann, och _inte_ om postnumret de facto är
* tilldelat ett geografiskt område. Funktionen särskiljer heller inte
* postnummer allokerade specifikt för postboxar.
*
* @param {String} number Postnummer
* @param {String} format Önskat format (1-3)
* @returns {String|Boolean} Postnumret eller "false"
*/

export function zipCode (number, format) {
  if (typeof number !== 'string') {
    return false
  }
  const n = number.replace(/\D/g, '')
  const pfx = ['32', '48', '49', '99']
  const pn = parseInt(n, 10)
  if (
    n.length !== 5 ||
    pn < 10000 ||
    pn > 99000 ||
    pfx.indexOf(n.substr(0, 2)) > -1
  ) {
    return false
  }
  return format === 'NAT_FORMAT'
    ? n.substr(0, 3) + ' ' + n.substr(-2)
    : format === 'INT_FORMAT' ? 'SE-' + n.substr(0, 3) + ' ' + n.substr(-2) : n
}
