export const readableList = (list) => {
  return list.length ? list.join(', ') : '-'
}

export const mapOptionSetValuesToText = (values, optionSet) => {
  if (!values || !optionSet) {
    return []
  }
  return optionSet.filter(o => values.indexOf(o.value) !== -1).map(o => o.text)
}

export const mapOptionSetValueToText = (value, optionSet) => {
  if (!value || !optionSet) {
    return []
  }
  return optionSet.filter(o => value === o.value).map(o => o.text)
}

export const scrollTo = (top) => {
  window.scrollTo({
    top: top,
    left: 0,
    behavior: 'smooth'
  }) // Uses smooth scroll with native support or smoothscroll-polyfill
}
