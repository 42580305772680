<template>
  <div>
    <form
      v-if="formData"
      novalidate="true"
      @submit.prevent="submitForm"
    >
      <div class="Newsletters-items">
        <div
          v-for="newsletter in formData"
          :key="newsletter.list_id"
          class="Grid--wider"
        >
          <div class="Grid__col Grid__col--4">
            <CheckboxInput
              :text="newsletter.name"
              v-model="newsletter.active"
              :inGroup="true"
            />
          </div>
        </div>
      </div>

      <SubmitButton
        :submitting="submitting"
      >
        Spara
      </SubmitButton>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { NETWORK_STATUSES } from '@/services/http'
import FormMixin from '@/components/form/FormMixin'
import CheckboxInput from '@/components/form/CheckboxInput'
import SubmitButton from '@/components/form/SubmitButton'

export default {
  name: 'NewslettersForm',
  components: { CheckboxInput, SubmitButton },
  mixins: [FormMixin],
  computed: {
    ...mapState({
      newsletters: state => state.newslettersStore.newsletters,
      newslettersNetworkStatus: state => state.newslettersStore.networkStatus
    }),
    submitting: function () {
      return this.newslettersNetworkStatus === NETWORK_STATUSES.SUBMITTING
    }
  },
  mounted () {
    this.initForm(this.newsletters)
  },
  methods: {
    ...mapActions([
      'updateNewsletters'
    ]),
    submitForm: function () {
      if (this.formIsValid()) {
        this.updateNewsletters(this.formData)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .Newsletters-items {
    margin-bottom: $spacing/2;
  }
</style>
