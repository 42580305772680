<template>
  <div>
    <ContentHeader>
      <h1>Sidan hittades inte</h1>
    </ContentHeader>
    <p>
      Sidan du försökte nå verkar inte finnas.
    </p>
    <AppMenu :extraMenuAtBottom="true" />
  </div>
</template>

<script>
import AppMenu from '@/components/AppMenu'
import ContentHeader from '@/components/ContentHeader'

export default {
  name: 'FileNotFoundRoute',
  components: { AppMenu, ContentHeader }
}
</script>
