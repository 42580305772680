<template>
  <div></div>
</template>

<script>
import { router } from '@/routes/router'

export default {
  name: 'CheckoutSuccessRoute',
  created () {
    router.push('/mina-kop')
  }
}
</script>
