import AppConfirmDialog from '@/components/AppConfirmDialog'
import Vue from 'vue'

const AppConfirmDialogClass = Vue.extend(AppConfirmDialog)

export default {
  bind (el, binding, vnode) {
    if (binding.value) {
      el.addEventListener('click', () => openConfirmDialog(vnode, binding))
    }
  }
}

function openConfirmDialog (vnode, binding) {
  const componentInstance = new AppConfirmDialogClass({
    propsData: {
      message: binding.value.message,
      onConfirm: binding.value.onConfirm,
      onConfirmArg: binding.value.onConfirmArg
    }
  })
  componentInstance.$mount()
  vnode.context.$el.appendChild(componentInstance.$el)
}
