import cloneDeep from 'lodash.clonedeep'

export function mockUpdateCall (object) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(cloneDeep(object))
    }, 1000)
  })
}

export function getMockUrl (url) {
  return '/api-mock' + url + '.json'
}
