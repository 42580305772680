<template>
  <div :class="wrapperClasses">
    <label
      v-if="label"
      :for="id"
      class="Form-component-wrapper__label"
    >
      {{ label }}
      <ToolTip v-if="tooltip">
        <p>{{ tooltip }}</p>
      </ToolTip>
    </label>

    <textarea v-if="type === 'textarea'"
      :id="id"
      :value="value"
      :class="inputClasses"
      :placeholder="placeholder"
      :disabled="disabled">
      @input="updateValue($event.target.value)"
      @blur="blur"
    </textarea>
    <input
      v-else
      :type="type"
      :value="value"
      :class="inputClasses"
      :placeholder="placeholder"
      :maxlength="maxlength()"
      :disabled="disabled"
      @input="updateValue($event.target.value)"
      @blur="blur"
    />

    <div
      v-if="validation && validation.$error"
      class="Form-component-wrapper__error"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import InputMixin from './InputMixin'

export default {
  name: 'TextInput',
  mixins: [InputMixin],
  props: {
    value: {
      type: [Number, String]
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    }
  },
  computed: {
    inputClasses: function () {
      return {
        'Text-input': true,
        'Text-input--has-error': this.validation && this.validation.$error,
        'Text-input--textarea': this.type === 'textarea'
      }
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value)
    },
    blur: function () {
      this.$emit('touch')
    },
    maxlength: function () {
      if (this.validation && this.validation.$params.maxLength) {
        return this.validation.$params.maxLength.max
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
  .Form-component-wrapper {
    &__error {
      position: absolute;
      top: 100%;
      margin-top: 2px;
      white-space: nowrap;
    }
  }
</style>
