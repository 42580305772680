<template>
  <div>
    <form
      v-if="formData"
      novalidate="true"
      @submit.prevent="submitForm"
    >
      <RadiobuttonsInput
        v-show="false"
        v-model="formData.payment_default"
        :options="[{value: 'invoice', text: 'Faktura'},{value: 'creditCard', text: 'Kort'}]"
        label="Förvald betalmetod"
        layout="horizontal"
      />

      <div class="Grid--wider">
        <div class="Grid__col Grid__col--4">
          <TextInput
            v-model="formData.account.orgno"
            type="text"
            label="Organisationsnummer"
            placeholder="NNNNNN-NNNN"
            :validation="$v.formData.account.orgno"
            @touch="touchInput('account', 'orgno')"
          />
        </div>
        <div class="Grid__col Grid__col--4">
          <RadiobuttonsInput
            v-model="hasPaymentAddress"
            :options="[{value: false, text: 'Registrerad företagsadress'},{value: true, text: 'Annan faktureringsadress'}]"
            label="Faktureringsadress"
            type="boolean"
            @input="clearPaymentAddress"
          />
        </div>
      </div>

      <template v-if="hasPaymentAddress">
        <div class="Grid--wider">
          <div class="Grid__col Grid__col--7">
            <TextInput
              v-model="formData.account.address_line1"
              type="text"
              label="Postadress"
              placeholder="Ange postadress för fakturor"
              :validation="$v.formData.account.address_line1"
              @touch="touchInput('account', 'address_line1')"
            />
          </div>
        </div>
        <div class="Grid--wider">
          <div class="Grid__col Grid__col--7">
            <TextInput
              v-model="formData.account.address_line2"
              type="text"
              label="Postadress, rad 2"
            />
          </div>
        </div>
        <div class="Grid--wider">
          <div class="Grid__col Grid__col--3">
            <TextInput
              v-model="formData.account.postalcode"
              type="text"
              label="Postnummer"
              placeholder="Ange postnummer"
              :validation="$v.formData.account.postalcode"
              @touch="touchInput('account', 'postalcode')"
            />
          </div>
          <div class="Grid__col Grid__col--4">
            <TextInput
              v-model="formData.account.city"
              type="text"
              label="Ort"
              placeholder="Ange ort"
              :validation="$v.formData.account.city"
              @touch="touchInput('account', 'city')"
            />
          </div>
        </div>
      </template>

      <SubmitButton
        :submitting="submitting"
      >
        Spara
      </SubmitButton>

      <a
        href
        class="Cancel-link"
        @click.prevent="unsetProfileEditArea()"
      >
        Avbryt
      </a>

      <div
        v-if="$v.$error"
        class="Form-error"
      >
        Formuläret innehåller fel.
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import { NETWORK_STATUSES } from '@/services/http'
import { paymentValidations, paymentValidationsWithAddress } from '@/models/profile'
import FormMixin from '@/components/form/FormMixin'
import TextInput from '@/components/form/TextInput'
import RadiobuttonsInput from '@/components/form/RadiobuttonsInput'
import SubmitButton from '@/components/form/SubmitButton'

export default {
  name: 'ProfilePaymentForm',
  components: { TextInput, RadiobuttonsInput, SubmitButton },
  mixins: [FormMixin],
  validations () {
    return {
      formData: this.hasPaymentAddress ? paymentValidationsWithAddress : paymentValidations
    }
  },
  data: function () {
    return {
      hasPaymentAddress: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profileStore.profile,
      profileNetworkStatus: state => state.profileStore.networkStatus
    }),
    submitting: function () {
      return this.profileNetworkStatus === NETWORK_STATUSES.SUBMITTING
    }
  },
  mounted () {
    this.initForm(this.profile)
    this.hasPaymentAddress = !!this.profile.account.address_line1
  },
  methods: {
    submitForm: function () {
      if (this.formIsValid()) {
        this.updateProfile(this.formData)
      }
    },
    ...mapActions([
      'updateProfile',
      'unsetProfileEditArea'
    ]),
    clearPaymentAddress: function (hasPaymentAddress) {
      this.formData = {
        ...this.formData,
        account: {
          ...this.formData.account,
          address_line1: null,
          address_line2: null,
          postalcode: null,
          city: null
        }
      }
    }
  }
}
</script>
