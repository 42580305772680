<template>
  <div :class="menuClasses">
    <div class="Menu__header">
      <h4 class="Menu__header__label">Inloggad som</h4>
      <div class="Menu__header__user">
        <div
          v-if="oidcUser"
          key="header_signedin"
          :title="oidcUser.email"
          class="Menu__header__user__name"
        >
          {{ oidcUser.given_name }} {{ oidcUser.family_name }}
          <span
            v-show="!oidcUser.given_name && !oidcUser.family_name"
            class="Menu__header__user__email"
          >{{ oidcUser.email }}</span>
        </div>
        <div
          v-else
          key="header_signedout"
        >
          &nbsp;
        </div>
      </div>
    </div>
    <div class="Menu__nav">
      <router-link
        v-for="menuRoute in menuRoutes"
        v-reset-scroll="!extraMenuAtBottom"
        :key="menuRoute.name"
        :to="menuRoute.path"
        :class="navLinkClassName(menuRoute)"
        @click.native="handleMenuItemClick($event)"
      >
        {{ menuRoute.title }}

        <span
          v-if="areaNotifications(menuRoute.name).length"
          class="Menu__notification"
        >
          {{ areaNotifications(menuRoute.name).length }}
        </span>
      </router-link>

      <a
        v-if="oidcUser"
        href
        class="Menu__nav__link Menu__nav__link--logout"
        @click.prevent="signOutOidc"
      >
        Logga ut
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { scrollTo } from '@/services/utils'

export default {
  name: 'AppMenu',
  props: {
    extraMenuAtBottom: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      menuHasBeenClosed: state => state.menuStore.menuHasBeenClosed
    }),
    ...mapGetters([
      'oidcUser',
      'areaNotifications'
    ]),
    menuClasses: function () {
      return {
        Menu: true,
        'Menu--signed-in': this.oidcUser,
        'Menu--extra-menu-at-bottom': this.extraMenuAtBottom
      }
    },
    menuRoutes: function () {
      return [
        {
          title: 'Min profil',
          name: 'profile',
          path: '/min-profil',
          root: true
        },
        {
          title: 'Mina tjänster',
          name: 'subscriptions',
          path: '/mina-kop'
        },
        // {
        //   title: 'Fakturor',
        //   name: 'invoices',
        //   path: '/fakturor'
        // },
        // {
        //   title: 'Mina nyhetsbrev',
        //   name: 'newsletters',
        //   path: '/mina-nyhetsbrev'
        // },
        // {
        //   title: 'Användarvillkor',
        //   name: 'terms',
        //   path: '/anvandarvillkor'
        // },
        // {
        //   title: 'Läs Chef digitalt',
        //   name: 'paperton',
        //   path: '/paperton'
        // },
        {
          title: 'Kontakt',
          name: 'contact',
          path: '/kontakt'
        }
      ]
    }
  },
  methods: {
    ...mapActions([
      'signOutOidc',
      'closeMenuOnSmallScreens'
    ]),
    navLinkClassName: function (route) {
      return {
        Menu__nav__link: true,
        'Menu__nav__link--current': this.isActiveRoute(route),
        'Menu__nav__link--current-and-menu-has-never-been-closed': this.isActiveRoute(route) && !this.menuHasBeenClosed
      }
    },
    isActiveRoute: function (route) {
      return this.baseRoutePath(this.$route.path) === route.path || (this.$route.path === '/' && route.root)
    },
    baseRoutePath: function (path) {
      if (path.match(/\//g).length > 1) {
        const secondSlachPosition = path.substr(1).indexOf('/') + 1
        return path.substr(0, secondSlachPosition)
      }
      return path
    },
    handleMenuItemClick: function (e) {
      if (this.extraMenuAtBottom) {
        scrollTo(0)
      } else if (!this.menuHasBeenClosed || e.target.className.indexOf('router-link-exact-active') > -1) {
        this.closeMenuOnSmallScreens()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .Menu {

    &--extra-menu-at-bottom {
      display: none;
    }

    &__header {
      @extend %content-header;
      opacity: 0;
      padding-top: 4px;

      .Menu--signed-in & {
        opacity: 1;
      }

      &__label {
        @extend %form-label;
      }

      &__user {
        padding-bottom: 6px;
        white-space: nowrap;

        &__name,
        &__email {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__nav {
      &__link {
        display: block;
        margin-bottom: $spacing/2;

        &--current {
          cursor: default;
          color: $focus-color;
        }

        &--logout {
          margin-top: $spacing*1.5;
        }
      }
    }

    &__notification {
      background: $action-color;
      border-radius: 999em;
      color: white;
      display: inline-block;
      font-size: 10px;
      height: 14px;
      line-height: 14px;
      position: relative;
      text-align: center;
      top: -6px;
      width: 14px;
    }

    @media screen and (max-width: $site_wrapper_width+1px) {
      // Small screen menu
      &__header {
        margin-bottom: 0;
        text-align: center;

        &__user {
          font-size: 20px;
          padding-bottom: $spacing/2;
        }
      }

      &__nav {
        &__link {
          border-bottom: 1px solid $border-color;
          font-size: 20px;
          line-height: 1;
          margin: 0;
          padding: $spacing 0;
          position: relative;

          &:after {
            content: '';
            background: url('@/assets/images/chevron-black.svg');
            height: 9px;
            right: 0;
            margin-top: -5px;
            position: absolute;
            top: 50%;
            transform: scale(1.25) rotate(-90deg);
            width: 14px;
          }

          &--current-and-menu-has-never-been-closed {
            color: inherit;
            cursor: pointer;
          }

          &--logout:after {
            content: none;
          }
        }
      }

      // Extra small screen menu at bottom
      &--extra-menu-at-bottom {
        display: block;
        margin-top: $spacing*2;

        .Menu__header {
          text-align: left;
        }
      }
    }
  }
</style>
