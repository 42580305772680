<template>
  <button
    :type="type"
    :class="buttonClasses"
    :disabled="submitting"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'PreLoader',
  props: {
    type: {
      type: String,
      default: 'submit'
    },
    submitting: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonClasses: function () {
      return {
        Button: true,
        'Submit-button': true,
        'Submit-button--submitting': this.submitting
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .Submit-button {
    position: relative;

    &--submitting:after {
      background: url('@/assets/images/button-loader.gif') no-repeat;
      background-size: 100%;
      content: '';
      position: absolute;
      right: 8px;
      top: 50%;
      height: 40px;
      width: 40px;
      margin-top: -20px;
    }
  }
</style>
