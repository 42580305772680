<template>
  <div>
    <div v-if="oidcUser && profile && subscriptions">
      <ContentHeader>
        <h1>Min profil</h1>
      </ContentHeader>
      <AreaNotifications area="profile" />
      <div class="Form-section">
        <h2>Inloggningsuppgifter</h2>
        <p>Behöver du ändra din e-postadress? Kontakta <a href="https://chef.se/kundservice/">kundservice</a> så får du hjälp!</p>
        <div>
          <div class="Grid--wider">
            <div class="Grid__col Grid__col--4">
              <div class="Field">
                <div class="Field__label">
                  E-post
                </div>
                {{ oidcUser.email }}
              </div>
            </div>
            <div class="Grid__col Grid__col--4">
              <div class="Field">
                <div class="Field__label">
                  Lösenord
                </div>
                ••••••••••
              </div>
            </div>
          </div>
          <a
            :href="ssoPasswordUrl"
            class="Focus-link"
          >
            Ändra lösenord
          </a>
        </div>
      </div>
      <div class="Form-section">
        <h2>Mina uppgifter</h2>
        <ProfilePersonForm v-if="profileEditArea === 'person'" />
        <div v-else>
          <div class="Grid--wider">
            <div class="Grid__col Grid__col--4">
              <div class="Field">
                <div class="Field__label">
                  Förnamn
                </div>
                {{ profile.firstname }}
              </div>
            </div>
            <div class="Grid__col Grid__col--4">
              <div class="Field">
                <div class="Field__label">
                  Efternamn
                </div>
                {{ profile.lastname }}
              </div>
            </div>
          </div>
          <a
            href
            @click.prevent="setProfileEditArea('person')"
          >
            Ändra uppgifter
          </a>
        </div>
      </div>
      <div class="Form-section">
        <h2>För dig som är medlem i Ledarna</h2>
        <ProfileMemberForm v-if="profileEditArea === 'member'" />
        <div v-else>
          <div class="Grid--wider">
            <div class="Grid__col Grid__col--4">
              <div class="Field">
                <div class="Field__label">
                  Medlemsnummer Ledarna
                  <ToolTip>
                    <p>
                      {{ profileTooltips.ledarnano }}
                    </p>
                  </ToolTip>
                </div>
                {{ profile.ledarnano || '-' }}
              </div>
            </div>
            <!--
            <div class="Grid__col Grid__col--4">
              <div class="Field">
                <div class="Field__label">
                  Postnummer
                  <ToolTip>
                    <p>
                      {{ profileTooltips.postalcode }}
                    </p>
                  </ToolTip>
                </div>
                {{ profile.postalcode || '-' }}
              </div>
            </div>
            -->
          </div>
            <a
              href
              @click.prevent="setProfileEditArea('member')"
            >
              Ändra medlemsuppgifter
            </a>
        </div>
      </div>
      <div class="Form-section">
        <h2>Mina tjänster</h2>
        <ProfileSubscriptionsSummary />
        <router-link
          v-if="activeSubscriptions.length"
          to="/mina-kop"
        >
        Hantera köpta tjänster
        </router-link>
        <div
          v-else
          class="Field"
        >
          Du har inga tjänster
        </div>
      </div>
      <div v-if="false" class="Form-section">
        <h2>Betalningsuppgifter</h2>
        <ProfilePaymentForm v-if="profileEditArea === 'payment'" />
        <div v-else>
          <div
            v-if="false"
            class="Grid--wider"
          >
            <div class="Grid__col Grid__col--4">
              <div class="Field">
                <div class="Field__label">
                  Förvalt betalsätt
                </div>
                {{ readablePaymentMethod }}
              </div>
            </div>
          </div>
          <div class="Grid--wider">
            <div class="Grid__col Grid__col--4">
              <div class="Field">
                <div class="Field__label">
                  Organisationsnummer
                </div>
                {{ profile.account.orgno || '-' }}
              </div>
            </div>
            <div class="Grid__col Grid__col--4">
              <div class="Field">
                <div class="Field__label">
                  Faktureringsadress
                </div>
                <template v-if="profile.account.address_line1">
                  <div>
                    {{ profile.account.address_line1 }}
                  </div>
                  <div>
                    {{ profile.account.address_line2 }}
                  </div>
                  <div>
                    <span>{{ profile.account.postalcode }}</span>
                    <span>{{ profile.account.city }}</span>
                  </div>
                </template>
                <div
                  v-else-if="profile.account.orgno"
                  key="profile_account_orgno"
                >
                  Registrerad företagsadress
                </div>
                <div
                  v-else
                  key="profile_account_orgno_none"
                >
                  -
                </div>
              </div>
            </div>
          </div>
          <a
            href
            @click.prevent="setProfileEditArea('payment')"
          >
            Ändra betalningsuppgifter
          </a>
        </div>
      </div>
      <AppMenu :extraMenuAtBottom="true" />
    </div>
    <PreLoader v-else />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import { readableList, mapOptionSetValueToText } from '@/services/utils'
import { profileTooltips } from '@/models/profile'
import AppMenu from '@/components/AppMenu'
import PreLoader from '@/components/PreLoader'
import ContentHeader from '@/components/ContentHeader'
import AreaNotifications from '@/components/AreaNotifications'
import ToolTip from '@/components/ToolTip'
import RouteMixin from '../route-mixin'
import ProfilePersonForm from './components/ProfilePersonForm'
import ProfileMemberForm from './components/ProfileMemberForm'
import ProfileSubscriptionsSummary from './components/ProfileSubscriptionsSummary'
import ProfilePaymentForm from './components/ProfilePaymentForm'

export default {
  name: 'ProfileRoute',
  components: { AppMenu, PreLoader, ContentHeader, AreaNotifications, ToolTip, ProfilePersonForm, ProfileMemberForm, ProfileSubscriptionsSummary, ProfilePaymentForm },
  mixins: [RouteMixin],
  computed: {
    ...mapState({
      profile: state => state.profileStore.profile,
      profileEditArea: state => state.profileStore.profileEditArea,
      subscriptions: state => state.subscriptionsStore.subscriptions
    }),
    ...mapGetters([
      'oidcUser',
      'activeSubscriptions'
    ]),
    ssoPasswordUrl: function () {
      return process.env.VUE_APP_SSO_PASSWORD_URL
    },
    profileTooltips: function () {
      return profileTooltips
    },
    readableTitle: function () {
      const list = mapOptionSetValueToText(this.profile.title, this.profile.options.title)
      return readableList(list)
    },
    readableBranch: function () {
      const list = mapOptionSetValueToText(this.profile.branch, this.profile.options.branch)
      return readableList(list)
    },
    readableCustomerType: function () {
      const list = mapOptionSetValueToText(this.profile.customertype, this.profile.options.customertype)
      return readableList(list)
    },
    readablePaymentMethod: function () {
      if (this.profile.payment_default === 'invoice') {
        return 'Faktura'
      }
      if (this.profile.payment_default === 'creditCard') {
        return 'Kort'
      }
      return '-'
    }
  },
  created () {
    this.requireCrmData(['Profile', 'Subscriptions'])
    if (this.profile) {
      if (!this.profile.firstname || !this.profile.lastname) {
        this.setProfileEditArea('person')
      } else if (!this.payment_default && (!this.account || !this.account.orgno)) {
        this.setProfileEditArea('payment')
      }
    }
  },
  destroyed () {
    this.unsetProfileEditArea()
  },
  methods: {
    ...mapActions([
      'setProfileEditArea',
      'unsetProfileEditArea'
    ])
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    @extend %title-M;
  }

  div > a {
    @extend %focus-link;
    &:after {
      background: url('@/assets/images/link-arrow.svg');
    }
  }
</style>
