<template>
  <ToggleArea :collapseTrigger="true">
    <template slot="trigger">
      <strong>{{ invoiceDate }}</strong>, Ordernummer: {{ subscription.order_no }}
    </template>
    <div
      slot="content"
      class="Payment__details"
    >
      <div class="Grid--wider">
        <div class="Grid__col Grid__col--2">
          <div class="Field">
            <div class="Field__label">
              Beställningsdatum
            </div>
            {{ invoiceDate }}
          </div>
        </div>
        <div class="Grid__col Grid__col--2">
          <div class="Field">
            <div class="Field__label">
              Ordernummer
            </div>
            {{ subscription.order_no }}
          </div>
        </div>
        <div class="Grid__col Grid__col--2">
          <div class="Field">
            <div class="Field__label">
              Belopp
            </div>
            {{ invoiceAmount }} kr
          </div>
        </div>
        <div class="Grid__col Grid__col--2">
          <div class="Field">
            <div class="Field__label">
              Betalsätt
            </div>
            {{ invoice.payment_type }}
          </div>
        </div>
        <div class="Grid__col Grid__col--2">
            <div class="Field__label">
              Kvitto
            </div>
          <a href>Visa kvitto</a>
        </div>
      </div>
    </div>
  </ToggleArea>
</template>

<script>
import priceString from '@/services/price-string'
import { invoiceDate } from '@/models/subscription'
import ToggleArea from '@/components/ToggleArea'

export default {
  name: 'SubscriptionPaymentItem',
  components: { ToggleArea },
  props: {
    subscription: {
      type: Object,
      required: true
    },
    invoice: {
      type: Object,
      required: true
    }
  },
  computed: {
    invoiceDate: function () {
      return invoiceDate(this.invoice)
    },
    invoiceAmount: function () {
      return priceString(this.invoice.amount)
    }
  }
}
</script>

<style lang="scss" scoped>

  a {
    @extend %focus-link;

    &:after {
      background: url('@/assets/images/link-arrow.svg');
    }
  }

  .Payment {
    &__details {
      background: $light-bg-color;
      padding: $spacing;

      @media screen and (min-width: $site_wrapper_width+0px) {
        padding-bottom: $spacing/4;
      }

      a {
        @extend %focus-link;
      }
    }
  }
</style>
